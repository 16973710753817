import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { AuthActionCreators } from "../../redux/actions/auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";

class SetPassword extends Component {
  state = {
    password: "",
    passwordConf: "",
    errors: {},
    loading: false,
    valid: 0,
    success: false,
  };

  async componentWillMount() {
    const {
      actions,
      match: {
        params: { token },
      },
    } = this.props;
    try {
      await actions.checkPwToken({ token });
      const { pwErr } = this.props;
      let valid = 1;
      if (pwErr) valid = 2;
      this.setState({ valid });
    } catch (e) {
      console.log("e", e);
      this.setState({ valid: 2 });
    }
  }

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const { passwordConf, password, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!password || password.length < 6) errors.password = "Required";
      if (!passwordConf || passwordConf.length < 6)
        errors.passwordConf = "Required";
      if (passwordConf && password && passwordConf !== password)
        errors.passwordConf = "Passwords Must Match";
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const {
          actions,
          match: {
            params: { token },
          },
        } = this.props;
        try {
          await actions.setPassword({ token, newPassword: password });
          const { loginErr } = this.props;
          if (loginErr)
            this.setState({ errors: { join: loginErr }, loading: false });
          else this.setState({ success: true, loading: false });
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  renderContent = () => {
    const {
      password,
      passwordConf,
      errors,
      loading,
      valid,
      success,
    } = this.state;
    if (valid === 0) {
      return <div>Loading...</div>;
    } else if (valid === 1) {
      if (success)
        return (
          <div>
            <p>Password successfully changed</p>
            <Link to="/login">Login</Link>
          </div>
        );
      return (
        <form onSubmit={this.handleSubmit}>
          <div className="form-container">
            <div className="form-content">
              <div className="wl-input-group">
                {errors.password && (
                  <div className="input-err-message">{errors.password}</div>
                )}
                <input
                  type="password"
                  className={"form-control" + (errors.password ? " error" : "")}
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => this.setValue("password", e.target.value)}
                />
              </div>
              <div className="wl-input-group">
                {errors.passwordConf && (
                  <div className="input-err-message">{errors.passwordConf}</div>
                )}
                <input
                  type="password"
                  className={
                    "form-control" + (errors.passwordConf ? " error" : "")
                  }
                  placeholder="Confirm New Password"
                  value={passwordConf}
                  onChange={(e) =>
                    this.setValue("passwordConf", e.target.value)
                  }
                />
              </div>
              {errors.join && <div className="form-error">{errors.join}</div>}
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={loading}
              >
                {loading ? "Resetting..." : "Reset"}
              </button>
            </div>
          </div>
        </form>
      );
    } else {
      return (
        <div>
          <p className="mb-5">Token is invalid</p>
          <Link to="/login" className="btn btn-outline-primary btn-block">
            Login
          </Link>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="view auth register">
        <SEO
          title="Set New Password | Customers"
          slug="set-password"
          creator="aesthetixconnect"
        />
        <div className="auth-row">
          <div className="auth-left-panel">
            <div className="auth-panel-inner">
              <div className="auth-title-bar">
                <h1>Reset Password</h1>
              </div>
              {this.renderContent()}
            </div>
          </div>
          <AuthRightPanel />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pwErr: state.AuthReducer.pwErr,
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
