import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import ReactGA from "react-ga";
import { AuthActionCreators } from "../../redux/actions/auth";
import { connect } from "react-redux";
import Login from "../../pages/auth/Login";
import Logout from "../../pages/auth/Logout";
import ForgotPassword from "../../pages/auth/ForgotPassword";
import Register from "../../pages/auth/Register";
import SetPassword from "../../pages/auth/SetPassword";
import AddDetails from "../../pages/on-boarding/AddDetails";
import { Services } from "../../pages/on-boarding/Services";
import Profile from "../../pages/Profile";
import Header from "./Header";
import { Toast } from "./Toast";
import ViewPartners from "../../pages/ViewPartners";
import ViewRFPs from "../../pages/rfps/ViewRFPs";
import CreateRFP from "../../pages/rfps/CreateRFP";
import VerifyEmail from "../../pages/on-boarding/VerifyEmail";
import SetTestimonial from "../../pages/SetTestimonial";
import Team from "../../pages/Team";
import Settings from "../../pages/Settings";
import AnalyticsTracker from "./AnalyticsTracker";
import RouteController from "./RouteController";
import SearchRequests from "../../pages/rfps/SearchRequests";
import CreateListing from "../../pages/rfps/CreateListing";

const ProtectedRoute = ({ isVerified, li, ...props }) => {
  return isVerified ? (
    <Route {...props} />
  ) : (
    <Redirect to={li ? "/profile" : "/login?return_url=" + props.path} />
  );
};

class Routing extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize('UA-178510820-2', {
      debug: false,
      titleCase: false
    });

    this.state = { canRender: false };
  }

  componentWillMount() {
    const { actions } = this.props;
    actions.checkToken().finally(() => this.setState({ canRender: true }));
  }

  render() {
    const { canRender } = this.state;
    const { isAuthed } = this.props;
    if (canRender) {
      return (
        <BrowserRouter>
          <ToastProvider components={{ Toast: Toast }}>
            <Header />
            <Switch>
              <ProtectedRoute
                isVerified={!isAuthed}
                li
                exact
                path="/register"
                component={AnalyticsTracker(Register)}
              />
              <ProtectedRoute
                isVerified={!isAuthed}
                li
                exact
                path="/login"
                component={AnalyticsTracker(Login)}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/logout"
                component={AnalyticsTracker(Logout)}
              />
              <ProtectedRoute
                isVerified={!isAuthed}
                li
                exact
                path="/forgot-password"
                component={AnalyticsTracker(ForgotPassword)}
              />
              <Route
                exact
                path="/set-password/:token"
                component={AnalyticsTracker(SetPassword)}
              />
              <Route
                exact
                path="/set-testimonial/:token"
                component={AnalyticsTracker(SetTestimonial)}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/"
                component={AnalyticsTracker(RouteController(ViewPartners))}
              />
              <Redirect exact from="/on-boarding" to="/on-boarding/verify" />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/on-boarding/verify"
                component={AnalyticsTracker(RouteController(VerifyEmail))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/on-boarding/details"
                component={AnalyticsTracker(RouteController(AddDetails))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/on-boarding/services"
                component={AnalyticsTracker(RouteController(Services))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/profile"
                component={AnalyticsTracker(RouteController(Profile))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/team"
                component={AnalyticsTracker(RouteController(Team))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/requests"
                component={AnalyticsTracker(RouteController(ViewRFPs))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                exact
                path="/marketplace"
                component={AnalyticsTracker(RouteController(SearchRequests))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/requests/create"
                component={AnalyticsTracker(RouteController(CreateRFP))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/listings/create"
                component={AnalyticsTracker(RouteController(CreateListing))}
              />
              <ProtectedRoute
                isVerified={isAuthed}
                path="/settings"
                component={AnalyticsTracker(RouteController(Settings))}
              />
              <Redirect to="/login" />
            </Switch>
          </ToastProvider>
        </BrowserRouter>
      );
    }
    return <div className="container">Loading...</div>;
  }
}

const mapStateToProps = (state) => ({
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
