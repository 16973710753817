import React from "react";
import Modal from "react-modal";
import {Link} from "react-router-dom";

export const InvitedUsersModal = props => {
        const {isOpen, close, onboarding = false} = props;
        return <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className="modal testimonial-requested"
            overlayClassName="modal-overlay"
            contentLabel="Invited Users Modal">
               <h1>Invites Sent</h1>
                <p>Invites have now been sent out to your team mates. You can proceed or add more of your team.</p>
            {onboarding ? <Link className="btn btn-primary" to="/on-boarding/testimonials">Next</Link> : <button className="btn btn-primary" onClick={close}>Done</button>}
        </Modal>
}
