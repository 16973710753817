import {applyMiddleware, createStore, compose} from "redux";
import thunk from "redux-thunk";
import Reducers from "./reducers/index";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    Reducers,
    undefined,
    composeEnhancers(applyMiddleware(thunk))
);

export default store;
