import React, {Component} from "react";
import Modal from "react-modal";
import {bindActionCreators} from "redux";
import {PartnerActionCreators} from "../../redux/actions/partner";
import {connect} from "react-redux";
import {withToastManager} from "react-toast-notifications";
import Select from "react-select";
import {CustomerActionCreators} from "../../redux/actions/customer";

class SuperAdminModal extends Component {
    state = {name:"", linkId: null, errors: {}, loading: false};

    setValue = (key, value) => this.setState({[key]: value});

    handleSubmit = async e => {
        e.preventDefault();
        const {linkId, loading} = this.state
        if (!loading) {
            let errors = {}
            this.setState({errors, loading: true})
            const {actions} = this.props;
            try {
                await actions.updateUser({linkId: linkId.value});
                const {userErr, close, toastManager} = this.props;
                if (userErr) this.setState({errors: {join: userErr}, loading: false});
                else {
                    toastManager.add("Successfully updated user", {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: false,
                    });
                    window.location.reload()
                }
            } catch (e) {
                this.setState({errors: {join: e.message || "Sorry, something went wrong"}, loading: false})
            }
        }
    }

    handleSearch = async () => {
        const {name} = this.state;
        const {actions} = this.props;
        await actions.searchByName({query: name});
    }

    loadLinks = () => {
        const {clients} = this.props;
        return clients.map(x =>({value: x.id, label: x.name}));
    }

    render() {
        const {isOpen, close} = this.props;
        const {name, linkId, errors, loading} = this.state;
        const links = this.loadLinks();
        return <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className="modal super-admin"
            overlayClassName="modal-overlay"
            contentLabel="Update User Modal">
            <h1>Super Admin</h1>
            {loading ? <div>Loading...</div> : <div>
                <div className="form-container">
                    <div className="form-content">
                        <label>Search By Name</label>
                        <div className="wl-input-group">
                            {errors.name && <div className="input-err-message">{errors.name}</div>}
                            <input type="text" className={"form-control" + (errors.name ? " error" : "")}
                                   placeholder="Client Name" value={name}
                                   onChange={e => this.setValue('name', e.target.value)}/>
                        </div>
                        <button type="button" className="btn btn-primary" onClick={this.handleSearch}
                                disabled={loading || name.length < 3}>{loading ? "Searching..." : "Search"}</button>

                        {links.length > 0 && <>

                        <Select
                            value={linkId}
                            backspaceRemovesValue={false}
                            className="BHG-search-select"
                            classNamePrefix="BHG-search-select"
                            onChange={x => this.setValue('linkId', x || null)}
                            options={this.loadLinks()}/>
                        {errors.join && <div className="form-error">
                            {errors.join}
                        </div>}
                        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}
                                disabled={loading}>{loading ? "Updating..." : "Update"}</button>
                        </>}
                    </div>
                </div>
            </div>}
        </Modal>
    }
}

const mapStateToProps = (state) => ({
    userErr: state.CustomerReducer.userErr,
    customerDetails: state.CustomerReducer.customerDetails,
    user: state.CustomerReducer.user,
    clients: state.CustomerReducer.clients,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CustomerActionCreators, dispatch),
})

export default withToastManager(connect(mapStateToProps, mapDispatchToProps)(SuperAdminModal));

