import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {Redirect} from "react-router-dom";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";

class Logout extends Component {
    render() {
        this.props.actions.logout()
        return <Redirect to="/login"/>
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AuthActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(Logout);
