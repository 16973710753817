import { GeneralActionTypes } from "../../types";
import { BytehogsAPI } from "../BytehogsAPI";

const SERVICE = "ac-cb";

export const GeneralActionCreators = {
  getBaseData: () => async (dispatch) => {
    dispatch({
      type: GeneralActionTypes.GET_BDATA_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken };
      const response = await BytehogsAPI(SERVICE, "getBaseData", payload);
      dispatch({
        type: GeneralActionTypes.GET_BDATA_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: GeneralActionTypes.GET_BDATA_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
};
