import React, { useState } from "react";
import { FiltersToggle } from "../global/FiltersToggle";
import { BytehogsPillContainer } from "../profile/BytehogsPillContainer";
import Select from "react-select";
import { SearchBox } from "../global/SearchBox";

const filterOptionsRequests = [
  { name: "Sectors", key: "sectors" },
  { name: "Services Category", key: "services" },
  { name: "Regions", key: "regions" },
];
const filterOptionsDistributors = [
  { name: "Sectors", key: "sectors" },
  { name: "Services Category", key: "services" },
  { name: "Regions", key: "regions" },
];

export const FiltersCard = (props) => {
  const {
    baseData,
    handlePillClick,
    sectors,
    regions,
    services,
    handleRegionSelect,
    searchByName,
    distributor = false
  } = props;
  const [isOpen, setOpen] = useState(false);
  const [activeFilter, setActive] = useState(0);
  const filterOptions = distributor ? filterOptionsDistributors : filterOptionsRequests;
  const activeItem = filterOptions[activeFilter];
  let anyActive;
  if(!distributor) {
    anyActive = sectors.length > 0 || services.length > 0;
  }
  else anyActive = sectors.length > 0 || regions.length > 0 || services.length > 0;

  const loadActiveRegions = () => {
    const regions = props[activeItem.key];
    return regions ? regions.map((x) => ({ value: x })) : [];
  };

  const loadRegions = () => {
    if (!baseData || !baseData.regions) return [];
    return baseData.regions.map((x) => ({ value: x.id, label: x.name }));
  };

  const removeRegion = (value) => {
    const regions = props.regions.filter((x) => x !== value && x);
    handleRegionSelect(regions);
  };

  return (
    <div className={"filters-card" + (isOpen ? " open" : "")}>
      <div className={"active-filters" + (anyActive ? " is-active" : "")}>
        <div onClick={() => setOpen(!isOpen)} className="toggle">
          <FiltersToggle />
        </div>
        <div className="filter-options">
          <div className="top">
            {filterOptions.map((x, i) => (
              <div
                key={x.name}
                className={"top-link" + (activeFilter === i ? " active" : "")}
                onClick={() => setActive(i)}
              >
                {x.name}
              </div>
            ))}
            {searchByName && <SearchBox search={searchByName} />}
          </div>
          {activeItem.key !== "regions" ? (
            <div className="bottom">
              <BytehogsPillContainer
                options={baseData[activeItem.key] || []}
                active={props[activeItem.key]}
                onClick={(x) => handlePillClick(activeItem.key, x)}
              />
            </div>
          ) : (
            <div className="bottom">
              <BytehogsPillContainer
                active={loadActiveRegions().map((x) => x.value)}
                options={baseData.regions || []}
                onClick={removeRegion}
                selectedOnly
              />
              <Select
                value={loadActiveRegions()}
                isMulti
                hideSelectedOptions
                controlShouldRenderValue={false}
                backspaceRemovesValue={false}
                className="BHG-search-select"
                classNamePrefix="BHG-search-select"
                onChange={(x) =>
                  handleRegionSelect(x.map((x) => x.value) || [])
                }
                options={loadRegions()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
