import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";
import { withToastManager } from "react-toast-notifications";
import { CustomerActionCreators } from "../../redux/actions/customer";

class VerifyEmail extends Component {
  state = {
    code: [],
    resentAt: null,
    errors: {},
    loading: false,
    modalOpen: false,
    image: null,
  };

  async componentDidMount() {
    const { actions } = this.props;
    await actions.getUser();
    const { user, history } = this.props;
    if (user.emailVerified === 1) history.push("/on-boarding/details");
  }

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const { code, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!code || code.length < 6) errors.code = "Code is required";
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const { actions } = this.props;
        try {
          await actions.verifyEmail(code.join(""));
          const { verifyEmailErr, history } = this.props;
          if (verifyEmailErr)
            this.setState({ errors: { join: verifyEmailErr }, loading: false });
          else history.push("/on-boarding/details");
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  handleLogoSuccess = (image) => this.setState({ modalOpen: false, image });

  handleCodeChange = (e, index) => {
    if (isNaN(Number(e.target.value))) return;
    let { code } = this.state;
    if (e.target.value.length <= 1) {
      code[index] = e.target.value;
      this.setState({ code }, () => {
        if (code[index]) {
          if (this.refs["ph_" + index].nextSibling)
            this.refs["ph_" + index].nextSibling.focus();
          else this.refs.ph_submit.focus();
        }
      });
    }
  };

  resendCode = async () => {
    const { resentAt } = this.state;
    const { actions, toastManager } = this.props;
    if (
      !resentAt ||
      (resentAt && resentAt.getTime() < new Date().getTime() - 1800)
    ) {
      await actions.resendEmailCode();
      toastManager.add("Resent code", {
        appearance: "success",
        autoDismiss: true,
        pauseOnHover: false,
      });
      this.setState({ resentAt: new Date() });
    }
  };

  renderResend = () => {
    return (
      <p>
        Didn't receive a code?{" "}
        <span className="btn btn-link btn-inline" onClick={this.resendCode}>
          Click here
        </span>{" "}
        to resend.
      </p>
    );
  };

  render() {
    const { code, errors, loading } = this.state;
    return (
      <div className="view auth onboarding verify">
        <SEO
          title="Verify Email for Aesthetix Connect | Partners"
          slug="on-boarding/verify"
          creator="aesthetixconnect"
        />
        <div className="auth-row">
          <div className="auth-left-panel">
            <div className="auth-panel-inner">
              <div className="auth-title-bar">
                <h1>Verify your email.</h1>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-container">
                  <div className="form-content">
                    <label>
                      Enter the six digit code sent to you via email
                    </label>
                    <div className="verify-boxes">
                      <input
                        type="number"
                        ref="ph_0"
                        className="form-control"
                        value={code[0]}
                        maxLength="1"
                        min={0}
                        max={9}
                        onChange={(e) => this.handleCodeChange(e, 0)}
                      />
                      <input
                        type="number"
                        ref="ph_1"
                        className="form-control"
                        value={code[1]}
                        min={0}
                        max="9"
                        onChange={(e) => this.handleCodeChange(e, 1)}
                      />
                      <input
                        type="number"
                        ref="ph_2"
                        className="form-control"
                        value={code[2]}
                        min={0}
                        max={9}
                        onChange={(e) => this.handleCodeChange(e, 2)}
                      />

                      <input
                        type="number"
                        ref="ph_3"
                        className="form-control"
                        value={code[3]}
                        min={0}
                        max={9}
                        onChange={(e) => this.handleCodeChange(e, 3)}
                      />
                      <input
                        type="number"
                        ref="ph_4"
                        className="form-control"
                        value={code[4]}
                        min={0}
                        max={9}
                        onChange={(e) => this.handleCodeChange(e, 4)}
                      />
                      <input
                        type="number"
                        ref="ph_5"
                        className="form-control"
                        value={code[5]}
                        min={0}
                        max={9}
                        onChange={(e) => this.handleCodeChange(e, 5)}
                      />
                    </div>
                    {this.renderResend()}
                    {errors.code && (
                      <div className="form-error">{errors.code}</div>
                    )}
                    {errors.join && (
                      <div className="form-error">{errors.join}</div>
                    )}
                    <button
                      type="submit"
                      ref="ph_submit"
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      {loading ? "Verifying..." : "Verify"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <AuthRightPanel />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  verifyEmailErr: state.CustomerReducer.verifyEmailErr,
  user: state.CustomerReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CustomerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
);
