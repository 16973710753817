import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SEO } from "../../components/global/SEO";
import { withToastManager } from "react-toast-notifications";
import { GeneralActionCreators } from "../../redux/actions/general";
import { CustomerActionCreators } from "../../redux/actions/customer";
import { RFPActionCreators } from "../../redux/actions/rfp";
import { Link } from "react-router-dom";
import { RequestsList } from "../../components/rfps/RFPsList";
import { PLMenu } from "../../components/global/PLMenu";
import { Back } from "../../components/global/Back";
import {BHGToggle} from "../../components/global/BHGToggle";

class ViewRFPs extends Component {
  state = { dataLoading: true, activeRequest: null };

  async componentWillMount() {
    const { genActions, actions, baseData, toastManager } = this.props;
    await actions.getAll();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
    const { requests } = this.props;
    let activeRequest = requests.length > 0 ? requests[0].id : null;
    this.setState({ dataLoading: false, activeRequest });
  }

  mapFilterValues = (baseData, filterType, filters) => {
    if (!baseData || JSON.stringify(baseData) === "{}") return null;
    const theValues = baseData[filterType];
    return filters
      .map((x) => theValues.find((y) => y.id === x && y).name)
      .join(", ");
  };

  updateRequest = async (data) => {
    const { actions } = this.props;
    const { activeRequest } = this.state;
    await actions.update(activeRequest, data);
    const { updateRequestErr, toastManager } = this.props;
    if (updateRequestErr) {
      console.log("updateRequestErr", updateRequestErr);
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    } else {
      await actions.getAll();
      toastManager.add("Successfully updated request.", {
        appearance: "success",
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  handleSelect = activeRequest => {
    document.querySelector('body').scrollTo(0,0)
    this.setState({activeRequest});
  }

  renderContent = () => {
    const { requests, baseData } = this.props;
    const { dataLoading, activeRequest } = this.state;
    const theRequest = activeRequest
      ? requests.find((x) => x.id === activeRequest && x)
      : null;
    if (dataLoading) return <div>Loading...</div>;
    if (requests.length === 0)
      return (
        <div className="text-center">
          <Link to="/requests/create" className="btn btn-primary mt-5">
            Create Your First Request
          </Link>
        </div>
      );
    return (
        <>
          <div className="col-12 create-intro">
            <p>Use <strong>‘Create New Request’</strong> if you are searching for a new product/device to be posted on the Distributor Forum.</p>
            <p>Use <strong>‘Create New Listing’</strong> if you are selling your used device/product to be posted on the marketplace.</p>
          </div>
      <div className="row">
        <div className="col-md-3">
          <RequestsList
            requests={requests}
            activeId={activeRequest}
            onSelect={this.handleSelect}
          />
        </div>
        <div className="col">
          {theRequest && (
            <div>
              <Back onClick={() => this.setState({ activeRequest: null })} />
              <h3>{theRequest.title}</h3>
              <div className="active-toggle">
                <span> {theRequest.open === 1 ? "Active" : "Inactive"}</span>
                <BHGToggle
                    active={theRequest.open === 1}
                    handleClick={() =>
                        this.updateRequest({ open: theRequest.open === 0 ? 1 : 0 })
                    }
                />
              </div>
              <h3>Summary</h3>
              <div className="partner-services-card">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row no-gutters">
                      <div className="col-md-5">
                        <span className="detail-title">Regions:</span>
                      </div>
                      <div className="col-md-7">
                        <span className="detail-value">
                          {this.mapFilterValues(
                            baseData,
                            "regions",
                            theRequest.filters.regions
                              ? theRequest.filters.regions || []
                              : []
                          )}
                        </span>
                      </div>
                      {(theRequest.filters.sub_regions && theRequest.filters.sub_regions.length > 0) && <>
                        <div className="col-md-5">
                          <span className="detail-title">Sub-regions:</span>
                        </div>
                        <div className="col-md-7">
                          <span className="detail-value">
                            {this.mapFilterValues(
                              baseData,
                              "sub_regions",
                              theRequest.filters.sub_regions ? theRequest.filters.sub_regions || [] : []
                            )}
                          </span>
                        </div>
                      </>}
                      <div className="col-md-5">
                        <span className="detail-title">Services:</span>
                      </div>
                      <div className="col-md-7">
                        <span className="detail-value">
                          {this.mapFilterValues(
                            baseData,
                            "services",
                            theRequest.filters.services
                              ? theRequest.filters.services || []
                              : []
                          )}
                        </span>
                      </div>
                      <div className="col-md-5">
                        <span className="detail-title">Sectors:</span>
                      </div>
                      <div className="col-md-7">
                        <span className="detail-value">
                          {this.mapFilterValues(
                            baseData,
                            "sectors",
                            theRequest.filters.sectors
                              ? theRequest.filters.sectors || []
                              : []
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row no-gutters">
                      <div className="col-md-5">
                        <span className="detail-title">{theRequest.listing === 1 ? "Desired Sale Price:" : "Expected Budget:"} </span>
                      </div>
                      <div className="col-md-7">
                        <span className="detail-value">
                          {theRequest.expectedBudget}
                        </span>
                      </div>
                    </div>
                    {theRequest.listing === 1 && <div className="row no-gutters">
                      <div className="col-md-5">
                        <span className="detail-title">Distributor:</span>
                      </div>
                      <div className="col-md-7">
                        <span className="detail-value">{theRequest.distributorName || theRequest.listingDistributor}</span>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="details">
                <h3>Description</h3>
                <p>{theRequest.description}</p>
              </div>
              <h3>Attached files</h3>
              <div className="attachments">
                {theRequest.attachments.map((attachment) => {
                  return (
                    <div key={attachment.id} className="attachment">
                      <img
                        src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-attachment.svg"
                        alt="Attachment"
                        className="attachment-icon"
                      />
                      <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                        <span>
                          {attachment.name.length > 10
                            ? attachment.name.substring(0, 7) +
                              "..." +
                              attachment.name.substr(attachment.name.length - 4)
                            : attachment.name}
                        </span>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
          </>
    );
  };

  render() {
    const { activeRequest } = this.state;
    return (
      <div className="view manage-rfps">
        <SEO
          title="View Requests on Aesthetix Connect | Customers"
          slug="/requests"
          creator="aesthetixconnect"
        />
        <div className="main-content">
          <PLMenu />
          <div className={"container" + (activeRequest ? " active" : "")}>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requests: state.RFPReducer.requests,
  updateRFPErr: state.RFPReducer.updateRFPErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(RFPActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
  cusActions: bindActionCreators(CustomerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(ViewRFPs)
);
