import React, { useState } from "react";
import ContactPartnerModal from "../modals/ContactPartnerModal";
import { SEO } from "../global/SEO";
import { TestimonialDetails } from "../partners/TestimonialDetails";
import { AwardDetails } from "../partners/AwardDetails";
import { TestimonialDetailsModal } from "../modals/TestimonialDetailsModal";
import {RatingContainer} from "../global/RatingContainer";

const mapFilterValues = (baseData, filterType, filters) => {
  if (!baseData || JSON.stringify(baseData) === "{}") return null;
  const theValues = baseData[filterType];
  return filters
    .map((x) => {
      let value = theValues.find((y) => y.id === x && y);
      return value ? value.name : null
    })
    .join(", ");
};

export const DistributorsDetails = (props) => {
  const [contact, setContact] = useState(false);
  const [activeTestimonial, setTestimonial] = useState({});
  const { distributor, distributorInfo, baseData, leaveTestimonial } = props;
  if (!distributor || !baseData) return null;
  return (
    <div className="partners-details">
      <SEO
        title={distributor.name + " on Aesthetix Connect | Customers"}
        slug=""
        creator="aesthetixconnect"
      />
      <div className="top-bar">
        <div>
          <img
            className="partner-logo"
            src={distributor.logo}
            alt={distributor.name + " logo"}
          />
        </div>
        <div className="top-details">
          <div className="name-bar">
            <h4>{distributor.name}</h4>
            <RatingContainer rating={Number(distributor.rating)} hideRating/>
          </div>
          <div className="buttons">
            <button
              className="btn btn-primary"
              onClick={() => setContact(true)}
            >
              Contact
            </button>
            <a
              href={distributor.website}
              target="_blank" rel="noopener noreferrer"
              className="btn btn-outline-primary"
            >
              Visit Website
            </a>
            <button
              className="btn btn-outline-primary"
              onClick={() => leaveTestimonial(distributor.id)}
            >
              Leave Review
            </button>
          </div>
        </div>
      </div>
      <h4>Summary</h4>
      <div className="partner-services-card">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <span className="detail-title">Services:</span>
              </div>
              <div className="col-md-8">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "services",
                    distributor.services
                      ? distributor.services.split(",").map(Number) || []
                      : []
                  )}
                </span>
              </div>
              <div className="col-md-4">
                <span className="detail-title">Sectors:</span>
              </div>
              <div className="col-md-8">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "sectors",
                    distributor.sectors
                      ? distributor.sectors.split(",").map(Number) || []
                      : []
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <span className="detail-title">Regions:</span>
              </div>
              <div className="col-md-8">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "regions",
                    distributor.regions
                      ? distributor.regions.split(",").map(Number) || []
                      : []
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="details">
        <h4>Company Description</h4>
        <p className="description">{distributor.bio}</p>
      </div>
      {distributorInfo && distributorInfo.awards.length > 0 && (
        <div className="awards">
          <h4>Awards</h4>
          {distributorInfo.awards.map((award) => (
            <AwardDetails key={award.id} {...award} />
          ))}
        </div>
      )}
      {distributorInfo &&
        distributorInfo.testimonials.length > 0 &&
        distributorInfo.testimonials[0].id && (
          <div className="testimonials">
            <h4>Reviews</h4>
            {distributorInfo.testimonials.map(
              (testimonial) =>
                testimonial.id && (
                  <TestimonialDetails
                    key={testimonial.id}
                    {...testimonial}
                    onClick={() => setTestimonial(testimonial)}
                  />
                )
            )}
          </div>
        )}
      <ContactPartnerModal
        isOpen={contact}
        close={() => setContact(false)}
        distributorId={distributor.id}
      />
      <TestimonialDetailsModal
        testimonial={activeTestimonial}
        isOpen={JSON.stringify(activeTestimonial) !== "{}"}
        close={() => setTestimonial({})}
      />
    </div>
  );
};
