import { PartnerActionTypes } from "../../types";

export const initialState = {
  loading: false,
  distributors: [],
  distributorsList: [],
  partnersInfo: {},
  searchErr: null,
  moreErr: null,
  contactErr: null,
  testimonialErr: null,
  requestByToken: null,
};

export const PartnerReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case PartnerActionTypes.SEARCH_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.SEARCH_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          searchErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        searchErr: null,
        distributors: payload.distributors,
      };
    }
    case PartnerActionTypes.GET_ALL_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.GET_ALL_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          searchErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        searchErr: null,
        distributorsList: payload.distributors,
      };
    }
    case PartnerActionTypes.MORE_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.MORE_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          moreErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        moreErr: null,
        partnersInfo: {
          ...state.partnersInfo,
          [payload.distributorId]: payload.data.info,
        },
      };
    }
    case PartnerActionTypes.CONTACT_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.CONTACT_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          contactErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        contactErr: null,
      };
    }
    case PartnerActionTypes.GET_TESTI_REQ_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.GET_TESTI_REQ_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          testimonialErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        testimonialErr: null,
        requestByToken: payload.request,
      };
    }
    case PartnerActionTypes.ADD_TESTI_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case PartnerActionTypes.ADD_TESTI_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          testimonialErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        testimonialErr: null,
      };
    }
    case PartnerActionTypes.LEAVE_TESTI_REQ: {
      return {
        ...state,
        loading: true,
        testimonialToken: null,
      };
    }
    case PartnerActionTypes.LEAVE_TESTI_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          testimonialErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        testimonialErr: null,
        testimonialToken: payload.testimonialToken,
      };
    }
    case "LOGOUT": {
      return initialState;
    }
    default:
      return state;
  }
};
