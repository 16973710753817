import React, {useState} from "react";
import UpdateUserModal from "../modals/UpdateUserModal";

export const AccountDetailsCard = props => {
    const {account} = props;
    const [edit, setEdit] = useState(false);

    return <div className= "account-details-card">
        <div className="row">
            <div className="col-5 col-md-4">
                <span className="detail-title">Name:</span>
            </div>
            <div className="col-7 col-md-5">
                <span className="detail-value">{account.name}</span>
            </div>
            <div className="col-5 col-md-4">
                <span className="detail-title">Email:</span>
            </div>
            <div className="col-7 col-md-5">
                <span className="detail-value">{account.email}</span>
            </div>
            {/*<div className="col-5 col-md-4">
                <span className="detail-title">Email Notifications:</span>
            </div>
            <div className="col-7 col-md-5">
                <span className="detail-value">{account.notifications === 1 ? "Enabled" : "Disabled"}</span>
            </div>*/}
        </div>
        <div className= "text-right">
            <button className="btn btn-link" onClick= {() => setEdit(true)}>Edit</button>
        </div>
        <UpdateUserModal isOpen={edit} close={() => setEdit(false)} user={account}/>
    </div>
}
