import {GeneralActionTypes} from "../../types";

export const initialState = {
  loading: false,
  baseDataErr: null,
  baseData: {}
};

export const GeneralReducer = (state = initialState, action) => {
  const {type, payload, error} = action;
  switch (type) {
     case GeneralActionTypes.GET_BDATA_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case GeneralActionTypes.GET_BDATA_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          baseDataErr: error
        }
      }
      return {
        ...state,
        loading: false,
        baseDataErr: null,
        baseData: payload.baseData
      }
    }
    case "LOGOUT": {
      return initialState
    }
    default:
      return state
  }
}
