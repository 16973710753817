import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GeneralActionCreators } from "../../redux/actions/general";
import { withToastManager } from "react-toast-notifications";
import { SEO } from "../../components/global/SEO";
import { FiltersCard } from "../../components/partners/FiltersCard";
import { PartnerActionCreators } from "../../redux/actions/partner";
import { RFPDetails } from "../../components/rfps/RFPDetails";
import { RFPActionCreators } from "../../redux/actions/rfp";
import { PLMenu } from "../../components/global/PLMenu";
import {CustomerActionCreators} from "../../redux/actions/customer";
import {Back} from "../../components/global/Back";
import {SearchRequestsList} from "./SearchRequestsList";
import {Link} from "react-router-dom";

class SearchRequests extends Component {
  state = {
    industries: [],
    services: [],
    regions: [],
    sectors: [],
    requests: [],
    activeId: null,
  };

  async componentWillMount() {
    const { genActions, baseData, toastManager } = this.props;
    await this.getCustomerData();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  }

  setValue = (key, value) => this.setState({ [key]: value });

  getCustomerData = async () => {
    const { cusActions } = this.props;
    await cusActions.getDetails();
    const { customerDetails, getDetailsErr, toastManager } = this.props;
    if (getDetailsErr) {
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    } else {
      this.setState({ ...customerDetails.filters });
      this.handleSearch();
    }
  };

  handlePillClick = (key, id, multi = true) => {
    let data = this.state[key];
    if (data.includes(id)) data.splice(data.indexOf(id), 1);
    else multi ? data.push(id) : (data = [id]);
    this.setState({ [key]: data }, () => this.handleSearch());
  };

  handleRegionSelect = (regions) => {
    this.setState({ regions },() => this.handleSearch());
  };

  handleSearch = async () => {
    const { actions } = this.props;
    const { sectors, services, regions, activeId } = this.state;
    await actions.search({ sectors, services, regions });
    const { searchErr, searchRequests, toastManager } = this.props;
    if (searchErr) {
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    } else {
      let newActiveId = null;
      if (activeId && searchRequests.find((x) => x.id === activeId && x))
        newActiveId = activeId;
      else if (searchRequests.length > 0) newActiveId = searchRequests[0].id;
      this.setState({ requests: searchRequests });

      if (window.screen.availWidth > 768) {
        this.setState({ activeId: newActiveId });
      }
    }
  };

  handleSearchSelect = (activeId) => {
    document.querySelector('body').scrollTo(0,0);
    this.setState({ activeId });
  };

  render() {
    const { services, sectors, regions, requests, activeId } = this.state;
    const { customerDetails, baseData } = this.props;
    const activeRequest = requests.find((x) => x.id === activeId && x);
    return (
      <div className="view view-partners">
        <SEO
          title="Search Requests on Aesthetix Connect | Customers"
          slug=""
          creator="aesthetixconnect"
        />
        <div className="main-content">
          <PLMenu />
          <div className={"container" + (activeRequest ? " active" : "")}>
            <section className="filters">
              <FiltersCard
                title="View all requests matching the following"
                baseData={baseData}
                customer={customerDetails}
                handlePillClick={this.handlePillClick}
                handleRegionSelect={this.handleRegionSelect}
                sectors={sectors}
                services={services}
                regions={regions}
              />
            </section>
            {requests.length === 0 || requests[0].id === null ? (
              <div className="text-center">
                <h1>No results found</h1>
                <p>
                  Please make sure you use the filters to navigate through the
                  requests
                </p>
                <Link to="/listings/create" className="btn btn-primary mt-4">Create Listing</Link>
              </div>
            ) : (
              <div className="row no-gutters">
                <div className="col-md-4">
                  <section className="partners-block">
                    <Link to="/listings/create" className="btn btn-primary">Create Listing</Link>
                    <SearchRequestsList
                      requests={requests}
                      onSelect={this.handleSearchSelect}
                      activeId={activeId}
                    />
                  </section>
                </div>
                <div className="col">
                  <section className="partners-block">
                    <Back onClick={() => this.setState({ activeId: null })} />
                    <RFPDetails request={activeRequest} baseData={baseData} />
                  </section>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerDetails: state.CustomerReducer.customerDetails,
  searchRequests: state.RFPReducer.searchRequests,
  searchErr: state.RFPReducer.searchErr,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(RFPActionCreators, dispatch),
  cusActions: bindActionCreators(CustomerActionCreators, dispatch),
  parActions: bindActionCreators(PartnerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(SearchRequests)
);
