import React, {useEffect, useState} from "react";
import { bindActionCreators } from "redux";
import { withToastManager } from "react-toast-notifications";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";
import { CustomerActionCreators } from "../../redux/actions/customer";

const ProfileDropdown = (props) => {
  const ref = React.useRef(null);
  const { customerDetails, user, actions, setAdminOpen } = props;
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  useOnClickOutside(ref, () => open && toggle());

  useEffect(() => {
    actions.getUser();
    if (JSON.stringify(customerDetails) === '{"filters":{}}') {
      actions.getDetails();
    }
  }, [])

  return (
    <div className={"profile-dropdown" + (open ? " open" : "")} ref={ref}>
      <div className="link" onClick={toggle}>
        <img src={customerDetails.logo} alt="logo" />
      </div>
      <div className="menu">
        <nav>
          <ul>
            <li>
              <Link to="/profile" onClick={toggle}>
                Company profile
              </Link>
            </li>
            <li className="separator" />
            <li>
              <Link to="/settings" onClick={toggle}>
                Account settings
              </Link>
            </li>

            {user && user.roleId === 1 && (
              <li>
                <Link to="/team" onClick={toggle}>
                  Manage team
                </Link>
              </li>
            )}
            <li className="separator" />
            {user && user.roleId === 3 && <>
              <li><span onClick={() => {setAdminOpen(true); toggle()}} className="link">Super Admin</span></li>
              <li className="separator"/>
            </>}
            <li>
              <Link to="/logout" onClick={toggle}>
                Sign out
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerDetails: state.CustomerReducer.customerDetails,
  user: state.CustomerReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CustomerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown)
);
