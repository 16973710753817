import React, { Component } from "react";
import { BytehogsPillContainer } from "../profile/BytehogsPillContainer";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { GeneralActionCreators } from "../../redux/actions/general";
import { connect } from "react-redux";
import { CustomerActionCreators } from "../../redux/actions/customer";

class OnBoardingService extends Component {
  state = { active: [], subActive: [], errors: {}, loading: false };

  async componentWillMount() {
    const {
      actions,
      genActions,
      baseData,
      customerDetails = false,
    } = this.props;
    if (JSON.stringify(customerDetails) === "{}") {
      try {
        await actions.getDetails();
        this.loadData();
      } catch (e) {
        console.log("e", e);
      }
    } else this.loadData();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        console.log("e", e);
      }
    }
  }

  loadData = () => {
    const {
      customerDetails: { filters },
      theKey,
    } = this.props;
    if (theKey === "regions") {
      const { regions, sub_regions } = filters;
      const theRegions = regions ? regions.map((x) => ({ value: x })) : [];
      const theSubRegions = sub_regions ? sub_regions.map((x) => ({ value: x })) : [];
      this.setState({ active: theRegions, subActive: theSubRegions });
    } else this.setState({ active: filters[theKey] || [] });
  };

  setValue = (key, value) => this.setState({ [key]: value });

  selectAll = () => {
    const { baseData, theKey } = this.props;
    let active = [];
    baseData[theKey].forEach((x) => active.push(x.id));
    this.setState({ active });
  };

  handlePillClick = (key, id, multi = true) => {
    let data = this.state.active;
    if (data.includes(id)) data.splice(data.indexOf(id), 1);
    else multi ? data.push(id) : (data = [id]);
    this.setState({ active: data });
  };

  loadRegions = () => {
    const { baseData } = this.props;
    if (!baseData || !baseData.regions) return [];
    return baseData.regions.map((x) => ({ value: x.id, label: x.name }));
  };

  removeRegion = (value) => {
    let { active } = this.state;
    active = active.map((x) => x.value !== value && x);
    if (active[0] === false) active = active.splice(0, 1);
    this.setState({ active });
  };

  loadSubRegions = () => {
    const {baseData} = this.props;
    const {active} = this.state;
    let subRegions = [];
    if(!baseData || !baseData.sub_regions) return subRegions;
    active.forEach(region => {
      let rawSubs = baseData.sub_regions.filter(x => x.regionId === region.value);
      if(rawSubs.length > 0) {
        subRegions.push(...rawSubs.map(x => ({value: x.id, label: x.name})))
      }
    });
    return subRegions;
  };

  removeSubRegion = value => {
    let {subActive} = this.state;
    subActive = subActive.map(x => (x.value !== value) && x);
    this.setState({subActive});
  }

  handleSubmit = async () => {
    const { loading, active, subActive } = this.state;
    const { theKey, profile = false } = this.props;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (active.length < 1) errors.active = "Required";
      if (Object.keys(errors).length > 0) {
        this.setState({ errors, loading: false });
        if (profile) throw new Error("override");
      } else {
        const { actions } = this.props;
        try {
          let data = active;
          if (theKey === "regions") {
            data = active.filter((e) => e).map((x) => x.value);
            let subData = subActive.filter((e) => e).map((x) => x.value);
            await actions.updateFilters('sub_regions', subData);
          }
          await actions.updateFilters(theKey, data);

          const { updateDetailsErr, updateFiltersErr, onSuccess } = this.props;
          if (updateDetailsErr || updateFiltersErr) {
            this.setState({
              errors: { join: updateDetailsErr || updateFiltersErr },
              loading: false,
            });
            if (profile) throw new Error("override");
          } else {
            this.setState({ loading: false });
            onSuccess();
          }
        } catch (e) {
          if (e.message !== "override") {
            this.setState({
              errors: { join: e.message || "Sorry, something went wrong" },
              loading: false,
            });
          } else {
            throw e;
          }
        }
      }
    }
  };

  render() {
    const { errors, loading, active, subActive } = this.state;
    const { baseData, title, theKey, profile = false } = this.props;
    let subRegions = this.loadSubRegions();
    return (
      <div className="onboarding-service">
        {theKey === "regions" ? (
          <div className="service">
            <div className="pill-group">
              <label>{title}</label>
              <div className="select-all">
                <span>Type a country or region into the search box below</span>
              </div>
              <BytehogsPillContainer
                active={active.map((x) => x.value)}
                options={baseData.regions || []}
                onClick={this.removeRegion}
                selectedOnly
              />
              <Select
                value={active}
                isMulti
                hideSelectedOptions
                controlShouldRenderValue={false}
                backspaceRemovesValue={false}
                className="BHG-search-select"
                classNamePrefix="BHG-search-select"
                onChange={(x) => this.setValue("active", x || [])}
                options={this.loadRegions()}
              />
              {subRegions.length > 0 && <>
                <label>What sub-region (County/Province/State) is your business located in?</label>
                <div className="select-all">
                  <span>Type a sub-region into the search box below</span>
                </div>
                <BytehogsPillContainer
                  active={subActive.map((x) => x.value)}
                  options={baseData.sub_regions || []}
                  onClick={this.removeSubRegion}
                  selectedOnly
                />
                <Select
                  value={subActive}
                  isMulti
                  hideSelectedOptions
                  controlShouldRenderValue={false}
                  backspaceRemovesValue={false}
                  className="BHG-search-select"
                  classNamePrefix="BHG-search-select"
                  onChange={(x) => this.setValue("subActive", x || [])}
                  options={subRegions}
                />
              </>}
              {errors.active && (
                <div className="form-error">{errors.active}</div>
              )}
            </div>
            {!profile && (
              <button
                className="btn btn-primary btn-block"
                onClick={this.handleSubmit}
                disabled={loading}
              >
                {loading ? "Saving..." : "Next"}
              </button>
            )}
          </div>
        ) : (
          <div className="service">
            <div className="pill-group">
              <label>{title}</label>
              <div className="select-all">
                <span>Select all that apply</span>
                <div className="BHG-pill-container">
                  <button className="BHG-pill active" onClick={this.selectAll}>
                    Select all
                  </button>
                </div>
              </div>
              <BytehogsPillContainer
                active={active}
                options={baseData[theKey] || []}
                onClick={(x) => this.handlePillClick(theKey, x)}
              />
              {errors.active && (
                <div className="form-error">{errors.active}</div>
              )}
            </div>
            {!profile && (
              <button
                className="btn btn-primary btn-block"
                onClick={this.handleSubmit}
                disabled={loading}
              >
                {loading ? "Saving..." : "Next"}
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerDetails: state.CustomerReducer.customerDetails,
  updateFiltersErr: state.CustomerReducer.updateFiltersErr,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CustomerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(OnBoardingService);
