import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { PartnerActionCreators } from "../redux/actions/partner";
import { connect } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import { SEO } from "../components/global/SEO";
import {RatingContainer} from "../components/global/RatingContainer";
import {TestimonialSuccessModal} from "../components/modals/TestimonialSuccessModal";

class SetTestimonial extends Component {
  state = {
    custName: "",
    custEmail: "",
    satisfaction: 0,
    prodSatisfaction: 0,
    trainingQuality: 0,
    salesRepRel: 0,
    partnershipFlex: 0,
    afterSalesService: 0,
    marketing: 0,
    reliability: 0,
    product: 0,
    recommendDist: 0,
    recommendProd: 0,
    optimiseProfit: null,
    optimiseAmount: "0 - 25%",
    comments: "",
    prodKnowledge: 0,
    errors: {},
    loading: false,
    valid: 0,
    success: false,
    isAnon: false
  };

  async componentWillMount() {
    const {
      actions,
      match: {
        params: { token },
      },
    } = this.props;
    try {
      await actions.getTestimonialByToken(token);
      const { testimonialErr, requestByToken } = this.props;
      let valid = 1;
      if (testimonialErr || !requestByToken) valid = 2;
      this.setState({ valid, isAnon: requestByToken?.userId ? false : true });
    } catch (e) {
      console.log("e", e);
      this.setState({ valid: 2 });
    }
  }

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      custName,
      custEmail,
      satisfaction,
      prodSatisfaction,
      trainingQuality,
      salesRepRel,
      partnershipFlex,
      afterSalesService,
      marketing,
      reliability,
      product,
      recommendDist,
      recommendProd,
      optimiseProfit,
      optimiseAmount,
      prodKnowledge,
      comments,
      loading,
        isAnon
    } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!custName || custName.length < 3) errors.custName = "Required";
      if(isAnon) {
        if (!custEmail) errors.custEmail = "Required";
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(custEmail)) errors.custEmail = "Invalid Email";
      }
      if (!product) errors.product = "Required";
      if (
          !satisfaction ||
          !prodSatisfaction ||
          !trainingQuality ||
          !salesRepRel ||
          !partnershipFlex ||
          !prodKnowledge ||
          !afterSalesService ||
          !marketing ||
          !reliability
      )
        errors.rating = "All Ratings are required";
      if (!recommendDist || !recommendProd)
        errors.recommend = "All Recommendations are required";
      if (![11, 12].includes(product)) {
        if (optimiseProfit !== 0 && optimiseProfit !== 1) errors.profit = "Required";
      }
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const {
          actions,
          match: {
            params: { token },
          },
        } = this.props;
        try {
          await actions.addTestimonial({
            token,
            custName,
            custEmail,
            satisfaction,
            prodSatisfaction,
            trainingQuality,
            salesRepRel,
            partnershipFlex,
            afterSalesService,
            prodKnowledge,
            marketing,
            reliability,
            product,
            recommendDist,
            recommendProd,
            optimiseProfit,
            optimiseAmount,
            comments,
          });
          const { testimonialErr } = this.props;
          if (testimonialErr)
            this.setState({ errors: { join: testimonialErr }, loading: false });
          else this.setState({ success: true, loading: false });
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  renderContent = () => {
    const {
      custName,
      custEmail,
      satisfaction,
      prodSatisfaction,
      trainingQuality,
      salesRepRel,
      partnershipFlex,
      afterSalesService,
      marketing,
      reliability,
      product,
      recommendDist,
      recommendProd,
      optimiseProfit,
      optimiseAmount,
      prodKnowledge,
      comments,
      errors,
      loading,
      valid,
      success,
        isAnon
    } = this.state;
    const { requestByToken } = this.props;

    if (valid === 0) {
      return <div>Loading...</div>;
    } else if (valid === 1) {
      if(success && !isAnon) return <Redirect to="/" />;
      else if(success) return <TestimonialSuccessModal isOpen={true}/>
      return (
          <form onSubmit={this.handleSubmit}>
            <h1>Leave a Testimonial</h1>
            <div className="form-container">
              <div className="form-content">
                <div className="row">
                  <div className="col-md-8 col-lg-6">
                    <div className="mb-1">(Name {isAnon && "& Email"} will not be visible)</div>
                    <div className="wl-input-group">
                      {errors.custName && (
                          <div className="input-err-message">{errors.custName}</div>
                      )}
                      <input
                          type="text"
                          className={
                            "form-control" + (errors.custName ? " error" : "")
                          }
                          placeholder="Your name"
                          value={custName}
                          onChange={(e) =>
                              this.setValue("custName", e.target.value)
                          }
                      />
                    </div>
                  </div>
                  {isAnon && <div className="col-md-6 col-lg-4">
                    <div className="mb-1 text-white">.</div>
                    <div className="wl-input-group">
                      {errors.custEmail && (
                          <div className="input-err-message">{errors.custEmail}</div>
                      )}
                      <input
                          type="email"
                          className={
                            "form-control" + (errors.custEmail ? " error" : "")
                          }
                          placeholder="Your email"
                          value={custEmail}
                          onChange={(e) =>
                              this.setValue("custEmail", e.target.value)
                          }
                      />
                    </div>

                  </div>}
                </div>

                <div className="row ratings-row">
                  <div className="col-md-2">
                    <label>What was your overall satisfaction?</label>
                    <RatingContainer rating={satisfaction} hideRating handleClick={e => this.setValue("satisfaction", e)}/>
                  </div>
                  <div className="col-md-2">
                    <label>Product Satisfaction?</label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("prodSatisfaction", e)}
                        rating={prodSatisfaction}
                        hideRating
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Training Quality?</label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("trainingQuality", e)}
                        rating={trainingQuality}
                        hideRating
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Sales Representative Relationship?</label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("salesRepRel", e)}
                        rating={salesRepRel}
                        hideRating
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Product Knowledge?</label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("prodKnowledge", e)}
                        rating={prodKnowledge}
                        hideRating
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Partnership Flexibility?</label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("partnershipFlex", e)}
                        rating={partnershipFlex}
                        hideRating
                    />
                  </div>
                  <div className="col-md-2">
                    <label>After Sales Service?</label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("afterSalesService", e)}
                        rating={afterSalesService}
                        hideRating
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Marketing?</label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("marketing", e)}
                        rating={marketing}
                        hideRating
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Reliability?</label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("reliability", e)}
                        rating={reliability}
                        hideRating
                    />
                  </div>
                  <div className="col-12">
                    {errors.rating && (
                        <div className="form-error">{errors.rating}</div>
                    )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6 col-lg-4">
                    <label>What product/service did you purchase?</label>
                    <div className="wl-input-group">
                      {errors.serviceProvided && (
                          <div className="input-err-message">
                            {errors.serviceProvided}
                          </div>
                      )}
                      <select
                          className={
                            "form-control" + (errors.product ? " error" : "")
                          }
                          value={product}
                          onChange={(e) =>
                              this.setValue("product", Number(e.target.value))
                          }
                      >
                        <option value="0">Choose Service...</option>
                        {requestByToken.services.map((service) => (
                            <option key={service.id} value={service.id}>
                              {service.name}
                            </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>
                      How likely are you to recommend this Distributor/Provider?
                    </label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("recommendDist", e)}
                        rating={recommendDist}
                        hideRating
                    />
                  </div>
                  <div className="col-md-3">
                    <label>
                      How likely are you to recommend the product that was chosen?
                    </label>
                    <RatingContainer
                        handleClick={(e) => this.setValue("recommendProd", e)}
                        rating={recommendProd}
                        hideRating
                    />
                  </div>
                  <div className="col-12">
                    {errors.recommend && (
                        <div className="form-error">{errors.recommend}</div>
                    )}
                  </div>
                </div>

                {product !== 0 && ![11, 12].includes(product) && (
                    <div className="project-completion-qs">
                      <div className="top">
                        <label>Did the product optimise profitability?</label>
                        {
                          <div className="yes-no-buttons">
                            <button
                                type="button"
                                className={
                                  "btn " +
                                  (optimiseProfit === 1
                                      ? "btn-success"
                                      : "btn-outline-success")
                                }
                                onClick={() => this.setValue("optimiseProfit", 1)}
                            >
                              Yes
                            </button>
                            <button
                                type="button"
                                className={
                                  "btn " +
                                  (optimiseProfit === 0
                                      ? "btn-danger"
                                      : "btn-outline-danger")
                                }
                                onClick={() => this.setValue("optimiseProfit", 0)}
                            >
                              No
                            </button>
                          </div>
                        }
                      </div>
                      {errors.profit && (
                          <div className="form-error">{errors.profit}</div>
                      )}
                      <div
                          className={
                            "content" + (optimiseProfit === 1 ? " active" : "")
                          }
                      >
                        <div className="internal">
                          <span>Profit increased by </span>
                          <select
                              className="form-control"
                              value={optimiseAmount}
                              onChange={(e) =>
                                  this.setValue("optimiseAmount", e.target.value)
                              }
                          >
                            <option value="0 - 25%">0 - 25%</option>
                            <option value="25 - 50%">25 - 50%</option>
                            <option value="50 - 75%">50 - 75%</option>
                            <option value="75 - 100%">75 - 100%</option>
                            <option value="100%+">100%+</option>
                          </select>
                        </div>
                      </div>
                    </div>
                )}
                <label>Any comments?</label>
                <div className="wl-input-group">
                  {errors.comments && (
                      <div className="input-err-message">{errors.comments}</div>
                  )}
                  <textarea
                      className={"form-control" + (errors.comments ? " error" : "")}
                      placeholder="Comments"
                      value={comments}
                      onChange={(e) => this.setValue("comments", e.target.value)}
                      rows={6}
                  />
                </div>
                {errors.join && <div className="form-error">{errors.join}</div>}
                <button
                    type="submit"
                    className="btn btn-primary submit-btn"
                    disabled={loading}
                >
                  {loading ? "Submitting..." : "Leave Testimonial"}
                </button>
              </div>
            </div>
          </form>
      );
    } else {
      return (
          <div>
            <h1>Token is invalid</h1>
            <Link to="/login">Login</Link>
          </div>
      );
    }
  };

  render() {
    return (
        <div className="view set-testimonial">
          <SEO
              title="Add Testimonial for Aesthetix Connect | Partners"
              slug="set-testimonial"
              creator="aesthetixconnect"
          />
          <div className="container">{this.renderContent()}</div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requestByToken: state.PartnerReducer.requestByToken,
  testimonialErr: state.PartnerReducer.testimonialErr,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetTestimonial);
