import React, {Component} from 'react';
import {bindActionCreators} from "redux";
/* import {RFPActionCreators} from "../../redux/actions/rfp"; */
import {GeneralActionCreators} from "../../redux/actions/general";
import {connect} from "react-redux";
import {CustomerActionCreators} from "../../redux/actions/customer";

export default function RouteController(WrappedComponent, options = {}) {
    const HOC = class extends Component {
        state = {loaded: false};

        async componentWillMount() {
           const {cusActions} = this.props;
           await cusActions.getDetails();
           await cusActions.getUser();
           this.setState({loaded: true});
           this.controller(this.props.location.pathname);
        }

        componentWillReceiveProps(nextProps) {
            const nextPage = nextProps.location.pathname;
            if(this.props.location.pathname !== nextPage) this.controller(nextPage);
        }

        controller = page => {
            const {user, customerDetails} = this.props;
            if(JSON.stringify(user) !== '{}' && JSON.stringify(customerDetails) !== '{filters:{}}') {
                const {bio, website, filters: {regions, sectors}} = customerDetails;
                if (user.emailVerified !== 1 && page !== '/on-boarding/verify') this.props.history.push('/on-boarding/verify');
                else if ((page !== '/profile' && page !== '/settings' && !/on-boarding/.test(page)) && (!bio || !website || !regions || !sectors)) {
                    this.props.history.push('/profile');
                }
            }
        }

        render() {
            if(this.state.loaded) return <WrappedComponent {...this.props} />;
            return <div>Loading...</div>
        }
    };

    const mapStateToProps = (state) => ({
        customerDetails: state.CustomerReducer.customerDetails,
        user: state.CustomerReducer.user,
    });

    const mapDispatchToProps = dispatch => ({
        cusActions: bindActionCreators(CustomerActionCreators, dispatch),
        genActions: bindActionCreators(GeneralActionCreators, dispatch),
    })

    return connect(mapStateToProps, mapDispatchToProps)(HOC);
}
