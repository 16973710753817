import { RFPActionTypes } from "../../types";
import { BytehogsAPI } from "../BytehogsAPI";
import axios from "axios";

const SERVICE = "ac-cb";

export const RFPActionCreators = {
  create: (data, attachments) => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.CREATE_RFP_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "create" };
      const response = await BytehogsAPI(SERVICE, "requests", payload);
      if (Object.keys(response.data.uploadURLs || {}).length > 0) {
        let uploads = [];
        attachments.forEach((x, i) =>
          uploads.push(axios.put(response.data.uploadURLs[i], x))
        );
        await Promise.all(uploads);
      }
      dispatch({
        type: RFPActionTypes.CREATE_RFP_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.CREATE_RFP_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getAll: () => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.GET_RFPS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken, operation: "getAll" };
      const response = await BytehogsAPI(SERVICE, "requests", payload);
      dispatch({
        type: RFPActionTypes.GET_RFPS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.GET_RFPS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  update: (requestId, data) => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.UPDATE_RFP_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { requestId, ...data, sessionToken, operation: "update" };
      await BytehogsAPI(SERVICE, "requests", payload);
      dispatch({
        type: RFPActionTypes.UPDATE_RFP_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.UPDATE_RFP_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  search: (data) => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.SEARCH_RFPS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "search" };
      const response = await BytehogsAPI(SERVICE, "requests", payload);
      dispatch({
        type: RFPActionTypes.SEARCH_RFPS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.SEARCH_RFPS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  contactCustomer: (data) => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.CONTACT_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "contact" };
      await BytehogsAPI(SERVICE, "requests", payload);
      dispatch({
        type: RFPActionTypes.CONTACT_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.CONTACT_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getRFPById: (requestId) => async (dispatch) => {
    dispatch({
      type: RFPActionTypes.GET_BY_ID_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { requestId, sessionToken, operation: "getById" };
      const response = await BytehogsAPI(SERVICE, "requests", payload);
      dispatch({
        type: RFPActionTypes.GET_BY_ID_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: RFPActionTypes.GET_BY_ID_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
};
