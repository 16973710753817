export const AuthActionTypes = {
  AUTH_STATUS: "AUTH_STATUS",
  REGISTER_REQ: "REGISTER_REQ",
  REGISTER_RES: "REGISTER_RES",
  LOGIN_REQ: "LOGIN_REQ",
  LOGIN_RES: "LOGIN_RES",
  FORGOT_PW_REQ: "FORGOT_PW_REQ",
  FORGOT_PW_RES: "FORGOT_PW_RES",
  SET_PW_REQ: "SET_PW_REQ",
  SET_PW_RES: "SET_PW_RES",
}

export const CustomerActionTypes = {
  VERIFY_EMAIL_REQ: "VERIFY_EMAIL_REQ",
  VERIFY_EMAIL_RES: "VERIFY_EMAIL_RES",
  UPDATE_DETAILS_REQ: "UPDATE_DETAILS_REQ",
  UPDATE_DETAILS_RES: "UPDATE_DETAILS_RES",
  GET_DETAILS_REQ: "GET_DETAILS_REQ",
  GET_DETAILS_RES: "GET_DETAILS_RES",
  UPDATE_USER_REQ: "UPDATE_USER_REQ",
  UPDATE_USER_RES: "UPDATE_USER_RES",
  GET_USER_REQ: "GET_USER_REQ",
  GET_USER_RES: "GET_USER_RES",
  UPLOAD_LOGO_REQ: "UPLOAD_LOGO_REQ",
  UPLOAD_LOGO_RES: "UPLOAD_LOGO_RES",
  UPDATE_FILTERS_REQ: "UPDATE_FILTERS_REQ",
  UPDATE_FILTERS_RES: "UPDATE_FILTERS_RES",
  INVITE_USERS_REQ: "INVITE_USERS_REQ",
  INVITE_USERS_RES: "INVITE_USERS_REQ",
  GET_USERS_REQ: "GET_USERS_REQ",
  GET_USERS_RES: "GET_USERS_RES",
  GET_CUSTOMERS_REQ: "GET_CUSTOMERS_REQ",
  GET_CUSTOMERS_RES: "GET_CUSTOMERS_RES",
}

export const PartnerActionTypes = {
  SEARCH_REQ: "SEARCH_REQ",
  SEARCH_RES: "SEARCH_RES",
  MORE_REQ: "MORE_REQ",
  MORE_RES: "MORE_RES",
  CONTACT_REQ: "CONTACT_REQ",
  CONTACT_RES: "CONTACT_RES",
  GET_TESTI_REQ_REQ: "GET_TESTI_REQ_REQ",
  GET_TESTI_REQ_RES: "GET_TESTI_REQ_RES",
  ADD_TESTI_REQ: "ADD_TESTI_REQ",
  ADD_TESTI_RES: "ADD_TESTI_RES",
  LEAVE_TESTI_REQ: "LEAVE_TESTI_REQ",
  LEAVE_TESTI_RES: "LEAVE_TESTI_RES",
  GET_ALL_REQ: "GET_ALL_REQ",
  GET_ALL_RES: "GET_ALL_RES"
}

export const GeneralActionTypes = {
  GET_BDATA_REQ: "GET_BDATA_REQ",
  GET_BDATA_RES: "GET_BDATA_RES",
}

export const RFPActionTypes = {
  CREATE_RFP_REQ: "CREATE_RFP_REQ",
  CREATE_RFP_RES: "CREATE_RFP_RES",
  GET_RFPS_REQ: "GET_RFPS_REQ",
  GET_RFPS_RES: "GET_RFPS_RES",
  UPDATE_RFP_REQ: "UPDATE_RFP_REQ",
  UPDATE_RFP_RES: "UPDATE_RFP_RES",
  SEARCH_RFPS_REQ: "SEARCH_RFPS_REQ",
  SEARCH_RFPS_RES: "SEARCH_RFPS_RES",
  CONTACT_REQ: "CONTACT_REQ",
  CONTACT_RES: "CONTACT_RES",
  GET_BY_ID_REQ: "GET_BY_ID_REQ",
  GET_BY_ID_RES: "GET_BY_ID_RES",
}
