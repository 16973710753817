import React from "react";

export const OnBoardingProgress = props => {
    const {index, steps} = props;
    const STEPS = Array.from(new Array(steps));

    return <div className="on-boarding-progress">
        <div className="steps">{STEPS.map((_, i) => <div className={"step" + (index >= i ? " active" : "")} key={i}/>)}</div>
    </div>
}
