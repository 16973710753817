import React from "react";

export const AuthRightPanel = () => (
  <div className="auth-right-panel">
    <div>
      {/* <img src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/images/partners/pl-partner-dashboard.png" /> */}
    </div>
    <h2>
        Aesthetix Connect creates an open and transparent marketplace for independent aesthetic professionals to connect with aesthetic distributors.
    </h2>
    <p>
        Instantly search hundreds of distributors and see feedback left by other customers. Create a request and instantly match with the most relevant service providers.
    </p>
  </div>
);
