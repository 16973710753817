import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GeneralActionCreators } from "../redux/actions/general";
import LogoModal from "../components/modals/LogoModal";
import { withToastManager } from "react-toast-notifications";
import { SEO } from "../components/global/SEO";
import { CustomerActionCreators } from "../redux/actions/customer";
import OnBoardingService from "../components/on-boarding/OnBoardingService";
import UpdateDetailsModal from "../components/modals/UpdateDetailsModal";

class Profile extends Component {
  state = { user: {}, modal: null, tab: 0, loading: false };
  sectors = createRef();
  areas = createRef();
  regions = createRef();
  subRegions = createRef();
  services = createRef();
  products = createRef();

  async componentWillMount() {
    const { genActions, baseData, toastManager } = this.props;
    await this.getCustomerData();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  }

  getCustomerData = async () => {
    const { actions } = this.props;
    await actions.getDetails();
    await actions.getUser();
    const { getDetailsErr, userErr, toastManager } = this.props;
    if (getDetailsErr || userErr) {
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  toggleModal = (modal = null) => this.setState({ modal });

  handleLogoSuccess = async () => {
    this.toggleModal();
    await this.getCustomerData();
  };

  handleSave = async () => {
    const { loading } = this.state;
    if (!loading) {
      const { toastManager } = this.props;
      try {
        this.setState({ loading: true });
        let actions = [];
        if (this.sectors.current)
          actions.push(this.sectors.current.handleSubmit());
        if (this.regions.current)
          actions.push(this.regions.current.handleSubmit());
        await Promise.all(actions);
        this.setState({ loading: false });
        toastManager.add("Updated details.", {
          appearance: "success",
          autoDismiss: true,
          pauseOnHover: false,
        });
      } catch (e) {
        console.log(e);
        this.setState({ loading: false });
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  };

  render() {
    const { modal, loading } = this.state;
    const { customerDetails, user } = this.props;
    const customer = customerDetails;

    return (
      <div className="view profile">
        <SEO
          title="Profile on Aesthetix Connect | Customers"
          slug="profile"
          creator="aesthetixconnect"
        />
        <div className="profile-content">
          <section className="static-top">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-2">
                  <img
                    src={customer.logo}
                    alt="logo"
                    className="partner-logo"
                    onClick={() => this.toggleModal("logo")}
                  />
                </div>
                <div className="col">
                  <div className="partner-details">
                    <h1>{customer.name}</h1>
                    <div className="buttons">
                      <a
                        href={customer.website}
                        target="_blank" rel="noopener noreferrer"
                        className="btn btn-outline-primary"
                      >
                        Visit website
                      </a>
                      {user && user.roleId === 1 && (
                        <button
                          className="btn btn-link"
                          onClick={() => this.toggleModal("details")}
                        >
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container">
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <h3>About</h3>
                    <p>{customer.bio}</p>
                  </div>
                  <div className="col-md-6">
                    <h3>Services</h3>
                    <OnBoardingService
                      title="What sector do you specialise in?"
                      theKey="sectors"
                      profile
                      ref={this.sectors}
                    />
                    <OnBoardingService
                      title="What region is your business located in?"
                      theKey="regions"
                      profile
                      ref={this.regions}
                    />
                    <div className="text-center">
                      <button
                        className="btn btn-primary"
                        onClick={this.handleSave}
                      >
                        {loading ? "Saving" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <LogoModal
          isOpen={modal === "logo"}
          close={this.toggleModal}
          success={this.handleLogoSuccess}
        />
        <UpdateDetailsModal
          isOpen={modal === "details"}
          close={this.toggleModal}
          success={this.handleLogoSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getDetailsErr: state.CustomerReducer.getDetailsErr,
  customerDetails: state.CustomerReducer.customerDetails,
  userErr: state.CustomerReducer.userErr,
  user: state.CustomerReducer.user,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CustomerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
