import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { GeneralActionCreators } from "../../redux/actions/general";
import { connect } from "react-redux";
import { OnBoardingIntro } from "./OnBoardingIntro";
import OnBoardingService from "./OnBoardingService";
import { Back } from "../global/Back";
import { OnBoardingProgress } from "./OnBoardingProgress";
import { CustomerActionCreators } from "../../redux/actions/customer";

class OnBoardingServicesSelection extends Component {
  async componentWillMount() {
    const { genActions, baseData, customerDetails } = this.props;
    if (JSON.stringify(customerDetails) === "{}") {
      this.refreshData();
    }
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        console.log("e", e);
      }
    }
  }

  nextStep = () => {
    const { activeStep, setStep } = this.props;
    this.refreshData();
    setStep(activeStep + 1);
  };

  refreshData = async () => {
    const { actions } = this.props;
    try {
      await actions.getDetails();
    } catch (e) {
      console.log("e", e);
    }
  };

  handleSuccess = async () => {
    const { handleSuccess } = this.props;
    await this.refreshData();
    handleSuccess();
  };

  render() {
    const { activeStep, setStep, skip } = this.props;
    return (
      <div className="onboarding-services-selection">
        <div className={"top" + (activeStep > 0 ? " active" : "")}>
          <Back onClick={() => setStep(activeStep - 1)} />
          <h1>Your services</h1>
          <OnBoardingProgress index={activeStep - 1} steps={2} />
        </div>
        <div className="services-container">
          <div className={"services-row step-" + activeStep}>
            <div>
              <OnBoardingIntro
                title="Your Services"
                intro="Select the services that your business offers in order for a seamless experience when searching for distributors or posting a request."
                nextText="Set my Services"
                nextAction={this.nextStep}
                skipAction={skip}
              />
            </div>
            <div>
              <OnBoardingService
                title="What sector do you specialise in?"
                theKey="sectors"
                step={0}
                onSuccess={this.nextStep}
              />
            </div>
            {/* <div>
                     <OnBoardingService
                         title="What business areas do you operate in?"
                         theKey="business_areas"
                         step={1}
                         onSuccess={this.nextStep}/>
                 </div>
                 <div>
                     <OnBoardingService
                         title="What services do you provide?"
                         theKey="services"
                         step={2}
                         onSuccess={this.nextStep}/>
                 </div>*/}
            <div>
              <OnBoardingService
                title="What region is your business located in?"
                theKey="regions"
                step={1}
                onSuccess={this.handleSuccess}
              />
            </div>
            {/* <div>
                     <OnBoardingService
                         title="What products do you support?"
                         theKey="products"
                         step={4}
                         onSuccess={handleSuccess}/>
                 </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerDetails: state.CustomerReducer.customerDetails,
  updateDetailsErr: state.CustomerReducer.updateDetailsErr,
  updateFiltersErr: state.CustomerReducer.updateFiltersErr,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CustomerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnBoardingServicesSelection);
