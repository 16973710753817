import React from "react";
import {Mascot} from "../global/Mascot";

export const OnBoardingIntro = props => {
    const {title, intro, nextAction, nextText, skipAction} = props;
    return <div className="onboarding-intro">
        <Mascot/>
        <h1>{title}</h1>
        <p>{intro}</p>
        <button className="btn btn-primary btn-block" onClick={nextAction}>{nextText}</button>
        <button className="btn btn-outline-primary btn-block" onClick={skipAction}>Do this later</button>
    </div>
}
