import React from "react";
const ratingStarActive = "https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-star-filled.svg";
const ratingStar = "https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-star.svg";

export const RatingContainer = props => {
    const {rating, handleClick = null, hideRating = false} = props
    return <div className={"rating-container " + (handleClick ? "clickable" : "")}>
        {(!handleClick && !hideRating) && <span className="rating">{rating}</span>}
        <img src={rating >= 1 ? ratingStarActive : ratingStar} className="rating-star" onClick={() => handleClick(1)} alt={1}/>
        <img src={rating >= 2 ? ratingStarActive : ratingStar} className="rating-star" onClick={() => handleClick(2)} alt={2}/>
        <img src={rating >= 3 ? ratingStarActive : ratingStar} className="rating-star" onClick={() => handleClick(3)} alt={3}/>
        <img src={rating >= 4 ? ratingStarActive : ratingStar} className="rating-star" onClick={() => handleClick(4)} alt={4}/>
        <img src={rating >= 5 ? ratingStarActive : ratingStar} className="rating-star" onClick={() => handleClick(5)} alt={5}/>
    </div>
}
