import {combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';
import {AuthReducer} from "./auth";
import {PartnerReducer} from "./partner";
import {CustomerReducer} from "./customer";
import {GeneralReducer} from "./general";
import {RFPReducer} from "./rfp";

const reducers = combineReducers({
    form: formReducer,
    AuthReducer,
    PartnerReducer,
    GeneralReducer,
    CustomerReducer,
    RFPReducer
});

export default reducers;
