import React from "react";

export const Back = ({ onClick }) => (
  <div className="pl-back" onClick={onClick}>
    <img
      src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-back.svg"
      alt="back"
    />
  </div>
);
