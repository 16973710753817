import React from "react";

export const AwardDetails = props => {
    const {received,name,description} = props;
    const receivedDate = new Date(received);
    return <div className="award-details">
        <div className="details">
            <h5>{name}</h5>
        <span>{receivedDate.toLocaleDateString()}</span>
        </div>
        <p>{description}</p>
    </div>
}
