import React, { Component } from "react";
import Modal from "react-modal";
import { bindActionCreators } from "redux";
import { PartnerActionCreators } from "../../redux/actions/partner";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";

class ContactPartnerModal extends Component {
  state = { subject: "", body: "", errors: {}, loading: false };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.isOpen && !this.props.isOpen)
      this.setState({ subject: "", body: "", loading: false, errors: {} });
  }

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const { subject, body, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!subject) errors.subject = "Required";
      if (!body) errors.body = "Required";
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const { actions, clientId } = this.props;
        try {
          await actions.contactCustomer({ subject, body, clientId });
          const { contactErr, close, toastManager } = this.props;
          if (contactErr)
            this.setState({ errors: { join: contactErr }, loading: false });
          else {
            toastManager.add("Successfully emailed partner", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: false,
            });
            close();
          }
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  render() {
    const { isOpen, close } = this.props;
    const { subject, body, errors, loading } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className="modal update-user contact-partner"
        overlayClassName="modal-overlay"
        contentLabel="Contact Client Modal"
      >
        <h1>Email Client</h1>
        <form onSubmit={this.handleSubmit}>
          <div className="form-container">
            <div className="form-content">
              <label>Subject</label>
              <div className="wl-input-group">
                {errors.subject && (
                  <div className="input-err-message">{errors.subject}</div>
                )}
                <input
                  type="text"
                  className={"form-control" + (errors.subject ? " error" : "")}
                  placeholder="Email Subject"
                  value={subject}
                  onChange={(e) => this.setValue("subject", e.target.value)}
                />
              </div>
              <label>Email Content</label>
              <div className="wl-input-group">
                {errors.body && (
                  <div className="input-err-message">{errors.body}</div>
                )}
                <textarea
                  className={"form-control" + (errors.body ? " error" : "")}
                  placeholder="Email Body"
                  value={body}
                  onChange={(e) => this.setValue("body", e.target.value)}
                  rows={10}
                />
              </div>
              {errors.join && <div className="form-error">{errors.join}</div>}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  contactErr: state.PartnerReducer.contactErr,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(ContactPartnerModal)
);
