import React from "react";
import { NavLink } from "react-router-dom";
export const PLMenu = (props) => {
  return (
    <div className="pl-menu">
      <nav>
        <NavLink exact to="/">
          Search Distributors
        </NavLink>
        <NavLink exact to="/marketplace">
            Marketplace
        </NavLink>
        <NavLink exact to="/requests">
          Manage Requests & Listings
        </NavLink>
      </nav>
    </div>
  );
};
