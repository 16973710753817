import React, {useState} from "react";
import {SEO} from "../global/SEO";
import ContactCustomerModal from "../modals/ContactCustomerModal";

const mapFilterValues = (baseData, filterType, filters) => {
  if (!baseData || JSON.stringify(baseData) === "{}") return null;
  const theValues = baseData[filterType];
  return filters
      .map((x) => {
        let value = theValues.find((y) => y.id === x && y);
        return value ? value.name : null
      })
      .join(", ");
};

export const RFPDetails = (props) => {
  const [contact, setContact] = useState(false);
  const {request, baseData} = props;
  if (!request || !baseData) return null;
  return (
    <div className="partners-details">
      <SEO
        title={request.title || request.customerName + " on Aesthetix Connect | Customers"}
        slug=""
        creator="aesthetixconnect"
      />
      <div className="top-bar">
        <div className="name-date">
          <h4>{request.name || request.title}</h4>
          <span>Posted on {new Date(request.createdAt).toDateString()}</span>
        </div>
        <button
          className="btn btn-primary"
          disabled={request.contacted > 0}
          onClick={() => setContact(true)}
        >
          Contact
        </button>
        {request.contacted > 0 && (
          <div>
            Contacted on {new Date(request.contactedDate).toLocaleDateString()}
          </div>
        )}
      </div>
      {request.anonymous === 0 && (
        <div className="details">
          <div className="row">
            <div className="col-md-4">
              <img
                className="partner-logo"
                src={request.logo}
                alt={request.customerName + " logo"}
              />
            </div>
            <div className="col">
              <div className="buttons">
                <h4>{request.customerName}</h4>
                <div className="BHG-pill-container">
                  <a
                    href={request.website}
                    target="_blank" rel="noopener noreferrer"
                    className="BHG-pill"
                  >
                    Visit website
                  </a>
                </div>
              </div>
              <p>{request.bio}</p>
            </div>
          </div>
        </div>
      )}
      <h2>Summary</h2>
      <div className="partner-services-card">
        <div className="row">
          <div className="col-md-7">
            <div className="row no-gutters">
              <div className="col-md-5">
                <span className="detail-title">Services:</span>
              </div>
              <div className="col-md-6 offset-md-1">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "services",
                    request.filters.services
                      ? request.filters.services || []
                      : []
                  )}
                </span>
              </div>
              <div className="col-md-5">
                <span className="detail-title">Sectors:</span>
              </div>
              <div className="col-md-6 offset-md-1">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "sectors",
                    request.filters.sectors ? request.filters.sectors || [] : []
                  )}
                </span>
              </div>
              <div className="col-md-5">
                <span className="detail-title">Regions:</span>
              </div>
              <div className="col-md-6 offset-md-1">
                <span className="detail-value">
                  {mapFilterValues(
                    baseData,
                    "regions",
                    request.filters.regions ? request.filters.regions || [] : []
                  )}
                </span>
              </div>
              {(request.filters.sub_regions && request.filters.sub_regions.length > 0) && <>
                <div className="col-md-5">
                  <span className="detail-title">Sub-regions:</span>
                </div>
                <div className="col-md-6 offset-md-1">
                  <span className="detail-value">
                    {mapFilterValues(
                      baseData,
                      "sub_regions",
                      request.filters.sub_regions ? request.filters.sub_regions || [] : []
                    )}
                  </span>
                </div>
              </>}
            </div>
          </div>
          <div className="col">
            <div className="row no-gutters sect-2">
              <div className="col-md-7">
                <span className="detail-title">{request.listing === 1 ? "Sale Price:" : "Avg Expected Budget:"}</span>
              </div>
              <div className="col-md-5">
                <span className="detail-value">{request.expectedBudget}</span>
              </div>
              {request.listing === 1 && <>
                <div className="col-md-12">
                  <span className="detail-title">Distributor:</span>
                  <span className="detail-value">{request.distributorName || request.listingDistributor}</span>
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Description</h2>
        <p className="description">{request.description}</p>
      </div>
      {request.attachments.length > 0 && <div>
        <h2>Attached files</h2>
        {request.attachments.map((attachment) => {
          return (
            <div>
              <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                {attachment.name}
              </a>
            </div>
          );
        })}
      </div>}
      <ContactCustomerModal
        isOpen={contact}
        close={() => setContact(false)}
        requestId={request.id}
      />
    </div>
  );
};
