import React from "react";
import {RatingContainer} from "../global/RatingContainer";

export const DistributorsList = (props) => {
  const { distributors, onSelect, activeId } = props;
  return (
    <div className="partners-list">
      <div className="intro">
        <h3>Top Results</h3>
      </div>
      <div className="list">
        {distributors.map((distributor) => (
          <div
            className={
              "partner-item" + (activeId === distributor.id ? " active" : "")
            }
            key={distributor.id}
            onClick={() => onSelect(distributor.id)}
          >
            <span className="partner-name">{distributor.name}</span>
              <RatingContainer rating={Number(distributor.rating)} hideRating/>
            <span className="partner-website">{distributor.website}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
