import React from "react";
import {BytehogsPill} from "./BytehogsPill";

export const BytehogsPillContainer = props => {
    const {options, active, onClick, selectedOnly = false} = props;
    let data = [];
    if(!selectedOnly) data = options;
    else options.forEach(x => (active.includes(x.id) && data.push(x)));

    return <div className="BHG-pill-container">
        {data.map(x => <BytehogsPill key={x.id} active={active.includes(x.id)} onClick={onClick} removable={selectedOnly} {...x}/>)}
    </div>
}
