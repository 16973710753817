import React from "react";
import {RatingContainer} from "../global/RatingContainer";

export const TestimonialDetails = props => {
    const {accumRating, onClick, productUsed, comments} = props;
    return <div className="testimonial-details" onClick={onClick}>
        <RatingContainer rating={Number(accumRating)} hideRating/>
        <div>
            <h5>{productUsed}</h5>
            <p>{comments.length > 30 ? comments.substring(0 , 30) + '...' : comments}</p>
        </div>
    </div>
}
