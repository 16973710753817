import React from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

export const PasswordResetModal = (props) => {
  const { isOpen, close } = props;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="modal testimonial-requested password-reset"
      overlayClassName="modal-overlay"
      contentLabel="Testimonial Requested Modal"
    >
      <img
        src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-email-sent.svg"
        alt="Email Sent"
        className="email-sent"
      />
      <h1>Check your email</h1>
      <p>We’ve sent your an email with instructions to reset your password!</p>
      <Link className="btn btn-primary" to="/login">
        Sign in
      </Link>
    </Modal>
  );
};
