import React from "react";
import Modal from "react-modal";
import {FullTestimonialDetails} from "../partners/FullTestimonialDetails";

export const TestimonialDetailsModal = props => {
        const {isOpen, close, testimonial} = props;

        return <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className={"modal animated testimonial-details"}
            overlayClassName="modal-overlay"
            contentLabel="Testimonial details Modal">
                <FullTestimonialDetails {...testimonial}/>
                <button className="btn btn-primary" onClick={close}>Close</button>
        </Modal>
}
