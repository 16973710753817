import React, { Component } from "react";
import Modal from "react-modal";
import { bindActionCreators } from "redux";
import { CustomerActionCreators } from "../../redux/actions/customer";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import ServicesSelection from "../profile/ServicesSelection";

class UpdateDetailsModal extends Component {
  state = {
    name: "",
    website: "",
    bio: "",
    errors: {},
    loading: false,
    tab: 0,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.isOpen && !this.props.isOpen)
      this.setState({
        name: nextProps.customerDetails.name,
        website: nextProps.customerDetails.website,
        bio: nextProps.customerDetails.bio,
        loading: false,
        errors: {},
        tab: 0,
      });
  }

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, website, bio, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!name) errors.name = "Required";
      if (!website || website.length < 6) errors.website = "Required";
      else if (
        !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/i.test(
          website
        )
      )
        errors.website = "Invalid Website";
      if (!bio || bio.length < 6) errors.bio = "Required";
      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const { actions } = this.props;
        try {
          await actions.updateDetails({ name, website, bio });
          const { userErr } = this.props;
          if (userErr)
            this.setState({ errors: { join: userErr }, loading: false });
          else this.success();
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  success = () => {
    const { actions, close, toastManager } = this.props;
    toastManager.add("Successfully updated details", {
      appearance: "success",
      autoDismiss: true,
      pauseOnHover: false,
    });
    close();
    actions.getDetails();
  };

  render() {
    const { isOpen, close } = this.props;
    const { website, name, bio, tab, errors, loading } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className="modal update-details"
        overlayClassName="modal-overlay"
        contentLabel="Update Details Modal"
      >
        <h1>Update Details</h1>
        <div className="tab-menu">
          <span
            className={"tab-item" + (tab === 0 ? " active" : "")}
            onClick={() => this.setValue("tab", 0)}
          >
            Company
          </span>
          {/*                                 <span className={"tab-item" + (tab === 1 ? " active" :"")} onClick={() => this.setValue('tab', 1)}>Services</span>
           */}{" "}
        </div>
        <div className="tab-content">
          {
            {
              0: (
                <form onSubmit={this.handleSubmit}>
                  <div className="form-container">
                    <div className="form-content">
                      <label>Company Name</label>
                      <div className="wl-input-group">
                        {errors.name && (
                          <div className="input-err-message">{errors.name}</div>
                        )}
                        <input
                          type="text"
                          className={
                            "form-control" + (errors.name ? " error" : "")
                          }
                          placeholder="Company Name"
                          value={name}
                          onChange={(e) =>
                            this.setValue("name", e.target.value)
                          }
                        />
                      </div>
                      <label>Website</label>
                      <div className="wl-input-group">
                        {errors.website && (
                          <div className="input-err-message">
                            {errors.website}
                          </div>
                        )}
                        <input
                          type="text"
                          className={
                            "form-control" + (errors.website ? " error" : "")
                          }
                          placeholder="Website"
                          value={website}
                          onChange={(e) =>
                            this.setValue("website", e.target.value)
                          }
                        />
                      </div>
                      <label>Description</label>
                      <div className="wl-input-group">
                        {errors.bio && (
                          <div className="input-err-message">{errors.bio}</div>
                        )}
                        <textarea
                          className={
                            "form-control" + (errors.bio ? " error" : "")
                          }
                          placeholder="Description."
                          value={bio}
                          onChange={(e) => this.setValue("bio", e.target.value)}
                          rows={4}
                        />
                      </div>
                      {errors.join && (
                        <div className="form-error">{errors.join}</div>
                      )}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {loading ? "Updating..." : "Update"}
                      </button>
                    </div>
                  </div>
                </form>
              ),
              1: <ServicesSelection onSuccess={this.success} profile />,
            }[tab]
          }
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userErr: state.CustomerReducer.userErr,
  customerDetails: state.CustomerReducer.customerDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CustomerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(UpdateDetailsModal)
);
