import React from "react";
import { Link } from "react-router-dom";

export const RequestsList = (props) => {
  const { requests, onSelect, activeId } = props;
  return (
    <div className="rfps-list">
      <div className="intro">
        <h3>My Requests</h3>
        <Link to="/requests/create" className="btn btn-primary btn-block">
          Create New Request
        </Link>
        <Link to="/listings/create" className="btn btn-outline-primary btn-block">
          Create New Listing
        </Link>
      </div>
      <div className="list">
        {requests.map((request) => (
          <div
            className={"rfp-item" + (activeId === request.id ? " active" : "")}
            key={request.id}
            onClick={() => onSelect(request.id)}
          >
            <span className="partner-name">{request.title}</span>
            <div className="bottom-details">
              {/*<div>
                <img
                  src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-clock.svg"
                  alt="clock"
                />
                {new Date(request.createdAt).toLocaleDateString()}
              </div>*/}
              <div>
                <img
                  src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-money.svg"
                  alt="money"
                />
                {request.expectedBudget}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
