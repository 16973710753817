import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { AuthActionCreators } from "../../redux/actions/auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SEO } from "../../components/global/SEO";
import { AuthRightPanel } from "../../components/on-boarding/AuthRightPanel";

class Register extends Component {
  state = {
    businessName: "",
    name: "",
    email: "",
    password: "",
    errors: {},
    loading: false,
  };

  setValue = (key, value) => {
    this.setState({ [key]: value });
    this.handleValidation(true);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { businessName, name, email, password, loading } = this.state;
    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      const validationErrors = this.handleValidation();
      if (validationErrors > 0) this.setState({ loading: false });
      else {
        const { actions } = this.props;
        try {
          await actions.register({ businessName, name, email, password });
          const { registerErr } = this.props;
          if (registerErr)
            this.setState({ errors: { join: registerErr }, loading: false });
          else {
            await actions.login({ email, password });
            const { loginErr, history } = this.props;
            if (loginErr) this.setState({ errors: { join: loginErr } });
            else history.push("/on-boarding/verify");
          }
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };

  handleValidation = (onlyExistingErrors = false) => {
    const { businessName, name, email, password } = this.state;
    if (
      (onlyExistingErrors && Object.keys(this.state.errors).length > 0) ||
      !onlyExistingErrors
    ) {
      let errors = {};
      this.setState({ errors });
      if (!email) errors.email = "Required";
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
        errors.email = "Invalid email";
      if (!businessName || businessName.length < 2)
        errors.businessName = "Required";
      if (!name || name.length < 2) errors.name = "Required";
      if (!password || password.length < 6) errors.password = "Required";
      if (Object.keys(errors).length > 0) this.setState({ errors });
      return Object.keys(errors).length;
    }
  };

  render() {
    const { businessName, name, email, password, errors, loading } = this.state;
    return (
      <div className="view auth register">
        <SEO
          title="Register for Aesthetix Connect | Customers"
          slug="register"
          creator="aesthetixconnect"
        />
        <div className="auth-row">
          <div className="auth-left-panel">
            <div className="auth-panel-inner">
              <div className="auth-title-bar">
                <h1>Sign up.</h1>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-container">
                  <div className="form-content">
                    <label>Your Name</label>
                    <div className="wl-input-group">
                      {errors.name && (
                        <div className="input-err-message">{errors.name}</div>
                      )}
                      <input
                        type="text"
                        className={
                          "form-control" + (errors.name ? " error" : "")
                        }
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => this.setValue("name", e.target.value)}
                      />
                    </div>
                    <label>Company Name</label>
                    <div className="wl-input-group">
                      {errors.businessName && (
                        <div className="input-err-message">
                          {errors.businessName}
                        </div>
                      )}
                      <input
                        type="text"
                        className={
                          "form-control" + (errors.businessName ? " error" : "")
                        }
                        placeholder="Company Name"
                        value={businessName}
                        onChange={(e) =>
                          this.setValue("businessName", e.target.value)
                        }
                      />
                    </div>
                    <label>Email</label>
                    <div className="wl-input-group">
                      {errors.email && (
                        <div className="input-err-message">{errors.email}</div>
                      )}
                      <input
                        type="email"
                        className={
                          "form-control" + (errors.email ? " error" : "")
                        }
                        placeholder="Business Email"
                        value={email}
                        onChange={(e) => this.setValue("email", e.target.value)}
                      />
                    </div>
                    <label>Password</label>
                    <div className="wl-input-group">
                      {errors.password && (
                        <div className="input-err-message">
                          {errors.password}
                        </div>
                      )}
                      <input
                        type="password"
                        className={
                          "form-control" + (errors.password ? " error" : "")
                        }
                        placeholder="Password"
                        value={password}
                        onChange={(e) =>
                          this.setValue("password", e.target.value)
                        }
                      />
                    </div>
                    {errors.join && (
                      <div className="form-error">{errors.join}</div>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      {loading ? "Creating..." : "Create account"}
                    </button>
                  </div>
                </div>
              </form>
              <div className="redirect-actions">
                <p>
                  Already have an account?
                  <Link to="/login" className="btn btn-link btn-inline">
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <AuthRightPanel />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  registerErr: state.AuthReducer.registerErr,
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
