import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { GeneralActionCreators } from "../redux/actions/general";
import { withToastManager } from "react-toast-notifications";
import { SEO } from "../components/global/SEO";
import { PLMenu } from "../components/global/PLMenu";
import { CustomerActionCreators } from "../redux/actions/customer";
import { FiltersCard } from "../components/partners/FiltersCard";
import { PartnerActionCreators } from "../redux/actions/partner";
import { DistributorsList } from "../components/partners/PartnersList";
import { DistributorsDetails } from "../components/partners/PartnersDetails";
import { Back } from "../components/global/Back";

class ViewPartners extends Component {
  state = {
    industries: [],
    sectors: [],
    business_areas: [],
    services: [],
    regions: [],
    products: [],
    distributors: [],
    activeId: null,
  };

  async componentWillMount() {
    const { genActions, baseData, toastManager } = this.props;
    await this.getCustomerData();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  }

  setValue = (key, value) => this.setState({ [key]: value });

  getCustomerData = async () => {
    const { cusActions } = this.props;
    await cusActions.getDetails();
    const { customerDetails, getDetailsErr, toastManager } = this.props;
    if (getDetailsErr) {
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    } else {
      this.setState({
        sectors: customerDetails.filters.sectors,
        regions: customerDetails.filters.regions,
      });
      this.handleSearch();
    }
  };

  handlePillClick = (key, id, multi = true) => {
    let data = this.state[key];
    if (data.includes(id)) data.splice(data.indexOf(id), 1);
    else multi ? data.push(id) : (data = [id]);
    this.setState({ [key]: data });
    this.handleSearch();
  };

  handleRegionSelect = (regions) => {
    this.setState({ regions });
    this.handleSearch();
  };

  handleSearch = async (query = null) => {
    const { actions } = this.props;
    const {
      industries,
      regions,
      sectors,
      business_areas,
      products,
      services,
      activeId,
    } = this.state;
    if (!query)
      await actions.search({
        areas: business_areas,
        products,
        sectors,
        services,
        industries,
        regions,
      });
    else await actions.searchByName({ query });
    const { searchErr, distributors, toastManager } = this.props;
    if (searchErr) {
      toastManager.add("Something went wrong.", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    } else {
      let newActiveId = null;
      if (activeId && distributors.find((x) => x.id === activeId && x))
        newActiveId = activeId;
      else if (distributors.length > 0) newActiveId = distributors[0].id;
      this.setState({ distributors });
      if (window.screen.availWidth > 768 && newActiveId) {
        this.handleDistributorSelect(newActiveId);
      }
    }
  };

  handleDistributorSelect = async (distributorId) => {
    const { actions } = this.props;
    try {
      await actions.getInfo(distributorId);
      document.querySelector('body').scrollTo(0,0);
      this.setState({ activeId: distributorId });
    } catch (e) {
      console.log("e", e);
      this.setState({ activeId: distributorId });
    }
  };

  handleLeaveTestimonial = async (distributorId) => {
    const { actions } = this.props;
    try {
      await actions.leaveTestimonial(distributorId);
      const { testimonialErr, testimonialToken, history } = this.props;
      if (testimonialErr) console.log("error");
      else history.push("/set-testimonial/" + testimonialToken);
    } catch (e) {
      console.log("e", e);
      this.setState({ activeId: distributorId });
    }
  };

  render() {
    const {
      industries,
      business_areas,
      sectors,
      services,
      regions,
      products,
      distributors,
      activeId,
    } = this.state;
    const { customerDetails, baseData, distributorInfo } = this.props;
    const activeDistributor = distributors.find((x) => x.id === activeId && x);
    return (
      <div className="view view-partners">
        <SEO
          title="View Distributors on Aesthetix Connect | Customers"
          slug=""
          creator="aesthetixconnect"
        />
        <div className="main-content">
          <PLMenu />
          <div className={"container" + (activeDistributor ? " active" : "")}>
            <section className="filters">
              <FiltersCard
                title="View all distributors matching the following"
                baseData={baseData}
                customer={customerDetails}
                handlePillClick={this.handlePillClick}
                handleRegionSelect={this.handleRegionSelect}
                industries={industries}
                business_areas={business_areas}
                sectors={sectors}
                regions={regions}
                products={products}
                services={services}
                searchByName={this.handleSearch}
                distributor
              />
            </section>
            {distributors.length === 0 ? (
              <div className="text-center">
                <h1>No results found</h1>
              </div>
            ) : (
              <div className="row no-gutters">
                <div className="col-md-4">
                  <section className="partners-block">
                    <DistributorsList
                      distributors={distributors}
                      onSelect={this.handleDistributorSelect}
                      activeId={activeId}
                    />
                  </section>
                </div>
                <div className="col">
                  <section className="partners-block">
                    <Back onClick={() => this.setState({ activeId: null })} />
                    <DistributorsDetails
                      distributor={activeDistributor}
                      distributorInfo={distributorInfo[activeId]}
                      baseData={baseData}
                      leaveTestimonial={this.handleLeaveTestimonial}
                    />
                  </section>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  distributors: state.PartnerReducer.distributors,
  distributorInfo: state.PartnerReducer.partnersInfo,
  searchErr: state.PartnerReducer.searchErr,
  testimonialToken: state.PartnerReducer.testimonialToken,
  testimonialErr: state.PartnerReducer.testimonialErr,
  getDetailsErr: state.CustomerReducer.getDetailsErr,
  customerDetails: state.CustomerReducer.customerDetails,
  baseDataErr: state.GeneralReducer.baseDataErr,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(PartnerActionCreators, dispatch),
  cusActions: bindActionCreators(CustomerActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(ViewPartners)
);
