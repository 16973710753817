import { CustomerActionTypes } from "../../types";
import { BytehogsAPI } from "../BytehogsAPI";
import axios from "axios";

const SERVICE = "ac-cb";

export const CustomerActionCreators = {
  updateDetails: (data) => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.UPDATE_DETAILS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken };
      const response = await BytehogsAPI(SERVICE, "updateDetails", payload);
      dispatch({
        type: CustomerActionTypes.UPDATE_DETAILS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.UPDATE_DETAILS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getDetails: () => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.GET_DETAILS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken };
      const response = await BytehogsAPI(SERVICE, "getDetails", payload);
      dispatch({
        type: CustomerActionTypes.GET_DETAILS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.GET_DETAILS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  verifyEmail: (code) => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.VERIFY_EMAIL_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken, operation: "verify", code };
      const response = await BytehogsAPI(SERVICE, "verifyEmail", payload);
      dispatch({
        type: CustomerActionTypes.VERIFY_EMAIL_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.VERIFY_EMAIL_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  resendEmailCode: () => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.VERIFY_EMAIL_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken, operation: "resend" };
      const response = await BytehogsAPI(SERVICE, "verifyEmail", payload);
      dispatch({
        type: CustomerActionTypes.VERIFY_EMAIL_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.VERIFY_EMAIL_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getUser: () => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.GET_USER_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { sessionToken, operation: "getCurrent" };
      const response = await BytehogsAPI(SERVICE, "user", payload);
      dispatch({
        type: CustomerActionTypes.GET_USER_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.GET_USER_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  updateUser: (data) => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.UPDATE_USER_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "update" };
      await BytehogsAPI(SERVICE, "user", payload);
      dispatch({
        type: CustomerActionTypes.UPDATE_USER_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.UPDATE_USER_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },

  uploadLogo: (image, fileType, fileExt) => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.UPLOAD_LOGO_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = {
        sessionToken,
        fileType,
        fileExt,
      };
      const response = await BytehogsAPI(SERVICE, "uploadLogo", payload);
      await axios.put(response.data.uploadURL, image);
      dispatch({
        type: CustomerActionTypes.UPLOAD_LOGO_RES,
        payload: null,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.UPLOAD_LOGO_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  updateFilters: (filterType, filters) => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.UPDATE_FILTERS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { filterType, filters, sessionToken };
      await BytehogsAPI(SERVICE, "updateFilters", payload);
      dispatch({
        type: CustomerActionTypes.UPDATE_FILTERS_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.UPDATE_FILTERS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getAllUsers: () => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.GET_USERS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { operation: "getAll", sessionToken };
      const response = await BytehogsAPI(SERVICE, "team", payload);
      dispatch({
        type: CustomerActionTypes.GET_USERS_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.GET_USERS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  inviteUsers: (users) => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.INVITE_USERS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { users, operation: "invite", sessionToken };
      await BytehogsAPI(SERVICE, "team", payload);
      dispatch({
        type: CustomerActionTypes.INVITE_USERS_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: CustomerActionTypes.INVITE_USERS_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },

  searchByName: data => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.GET_CUSTOMERS_REQ
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      let payload = {...data, sessionToken, operation: "searchByName"};
      const response = await BytehogsAPI(SERVICE, 'user', payload);
      dispatch({
        type: CustomerActionTypes.GET_CUSTOMERS_RES,
        payload: response.data
      })
    } catch (e) {
      console.log("e", e)
      dispatch({
        type: CustomerActionTypes.GET_CUSTOMERS_RES,
        error: e.data ? e.data.message : "Something went wrong"
      })
    }
  },
};
