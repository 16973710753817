import React, {Component} from "react";
import {BytehogsPillContainer} from "./BytehogsPillContainer";
import Select from "react-select";
import {bindActionCreators} from "redux";
import {CustomerActionCreators} from "../../redux/actions/customer";
import {GeneralActionCreators} from "../../redux/actions/general";
import {connect} from "react-redux";

const employeesData = [{id: 1, name: "1"}, {id: 2, name: "1 - 20"}, {id: 3, name: "20 - 50"}, {id: 4, name: "50+"}];

class ServicesSelection extends Component {
    state = {industries: [], regions: [], subRegions: [], employees: [], errors: {}, loading: false};

    async componentWillMount() {
        const {actions, genActions, baseData, customerDetails} = this.props;
        if(JSON.stringify(customerDetails) === '{}') {
            try {await actions.getDetails(); this.loadData();}
            catch (e) {console.log("e", e);};
        } else this.loadData()
        if(JSON.stringify(baseData) === '{}') {
            try {await genActions.getBaseData();}
            catch (e) {console.log("e", e);};
        }
    }

    loadData = () => {
        const {customerDetails: {filters, employees}} = this.props;
        const {industries, regions} = filters;
        const theRegions = regions ? regions.map(x => ({value: x})) : [];
        const theEmployess = [employeesData.find(x => (x.name === employees) && x).id];
        this.setState({industries, regions: theRegions, employees: theEmployess})
    }

    setValue = (key, value) => this.setState({[key]:value});

    handlePillClick = (key, id, multi = true) => {
        let data = this.state[key];
        if(data.includes(id)) data.splice(data.indexOf(id), 1);
        else multi ? data.push(id) : data = [id];
        this.setState({[key]: data});
    }

    loadRegions = () => {
        const {baseData} = this.props;
        if(!baseData || !baseData.regions) return [];
        return baseData.regions.map(x =>({value: x.id, label: x.name}));
    };

    removeRegion = value => {
        let {regions} = this.state;
        regions = regions.map(x => (x.value !== value) && x);
        this.setState({regions});
    }

    loadSubRegions = () => {
        const {baseData, regions} = this.props;
        let subRegions = [];
        if(!baseData || !baseData.subRegions) return subRegions;
        regions.forEach(region => {
            subRegions.push(baseData.subRegions.filter(x => x.regionId === region.id).map(x => ({value: x.id, label: x.name})))
        });
        console.log("subRegions", subRegions)
        return subRegions;
    };

    removeSubRegion = value => {
        let {subRegions} = this.state;
        subRegions = subRegions.map(x => (x.value !== value) && x);
        this.setState({subRegions});
    }

    handleSubmit = async () => {
        const {loading, industries, regions, employees} = this.state;
        let theRegions = regions.filter(e => e).map(x => x.value);
        if(!loading) {
            let errors = {}
            this.setState({errors, loading: true})
            if (industries.length < 1) errors.industries = 'Required';
            if (theRegions.length < 1) errors.regions = 'Required';
            if (employees.length < 1) errors.employees = 'Required';
            if (Object.keys(errors).length > 0) this.setState({errors, loading: false});
            else {
                const {actions} = this.props
                try {
                    const theEmployees = employeesData.find(x => (x.id === employees[0]) && x).name;
                    await actions.updateDetails({employees: theEmployees});
                    await actions.updateFilters('industries', industries);
                    await actions.updateFilters('regions', theRegions);
                    const {updateDetailsErr, updateFiltersErr, onSuccess} = this.props;
                    if(updateDetailsErr || updateFiltersErr) this.setState({errors: {join: updateDetailsErr || updateFiltersErr}, loading: false});
                    else onSuccess()
                } catch(e) {
                    this.setState({errors: {join: e.message || "Sorry, something went wrong"}, loading: false})
                }
            }
        }
    }


    render() {
        const {errors,loading, industries, regions, employees} = this.state;
        const {baseData, profile = false} = this.props;
        let theSubRegions = this.loadSubRegions();
        return <div className="services-selection">
            <div className="pill-group">
                <label>What industries do you cater to?</label>
                <p>Select all that apply</p>
                <BytehogsPillContainer active={industries} options={baseData.industries || []}
                                       onClick={x => this.handlePillClick('industries', x)}/>
                {errors.industries && <div className="form-error">{errors.industries}</div>}
            </div>
            <div className="pill-group">
                <label>What region is your business located in?</label>
                <BytehogsPillContainer active={regions.map(x => x.value)} options={baseData.regions || []}
                                       onClick={this.removeRegion} selectedOnly/>
                <Select
                    value={regions}
                    isMulti
                    hideSelectedOptions
                    controlShouldRenderValue={false}
                    backspaceRemovesValue={false}
                    className="BHG-search-select"
                    classNamePrefix="BHG-search-select"
                    onChange={x => this.setValue('regions', x || [])}
                    options={this.loadRegions()}
                />
                {errors.regions && <div className="form-error">{errors.regions}</div>}

                {theSubRegions.length > 0 && <>
                        <label>What region is your business located in?</label>
                        <BytehogsPillContainer active={regions.map(x => x.value)} options={baseData.regions || []}
                        onClick={this.removeRegion} selectedOnly/>
                        <Select
                        value={regions}
                        isMulti
                        hideSelectedOptions
                        controlShouldRenderValue={false}
                        backspaceRemovesValue={false}
                        className="BHG-search-select"
                        classNamePrefix="BHG-search-select"
                        onChange={x => this.setValue('regions', x || [])}
                        options={this.loadRegions()}
                        />
                    </>
                }
            </div>
            <div className="pill-group">
                <label>How many employees do you have?</label>
                <BytehogsPillContainer active={employees} options={employeesData}
                                       onClick={x => this.handlePillClick('employees', x, false)}/>
                {errors.employees && <div className="form-error">{errors.employees}</div>}
            </div>
            {errors.join && <div className="form-error">{errors.join}</div>}
            <button className="btn btn-primary" onClick={this.handleSubmit}
                    disabled={loading}>{loading ? "Saving..." : (profile ? "Save" : "Next")}</button>
        </div>
    }
}


const mapStateToProps = (state) => ({
    customerDetails: state.CustomerReducer.customerDetails,
    updateDetailsErr: state.CustomerReducer.updateDetailsErr,
    updateFiltersErr: state.CustomerReducer.updateFiltersErr,
    baseDataErr: state.GeneralReducer.baseDataErr,
    baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CustomerActionCreators, dispatch),
    genActions: bindActionCreators(GeneralActionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ServicesSelection);

