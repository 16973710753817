import React from "react";

export const FiltersToggle = (props) => (
  <img
    src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-filters.svg"
    alt="filters"
    className={"filters-toggle " + (props.className || "")}
    {...props}
  />
);
