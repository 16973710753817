import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {CustomerActionCreators} from "../../redux/actions/customer";
import {connect} from "react-redux";

class UsersList extends Component {
    componentWillMount() {
        const {actions} = this.props;
        actions.getAllUsers();
    }

    render() {
        const {allUsers} = this.props;
        return <div className="users-list">
            <div className="row">
                <div className="col-4 col-md-3">
                    <label>Name</label>
                </div>
                <div className="col-8 col-md-6">
                    <label>Email</label>
                </div>
                <div className="col-3 d-none d-md-block">
                    <label>Role</label>
                </div>
            </div>

            {allUsers.map((x,i) => {
                return <div className="row" key={i}>
                    <div className="col-4 col-md-3">
                        <span>{x.name}</span>
                    </div>
                    <div className="col-8 col-md-6">
                        <span>{x.email}</span>
                    </div>
                    <div className="col-2 d-none d-md-block">
                        <span>{x.role}</span>
                    </div>
                    <div className="col-1 d-none d-md-block">
                        <div className= {"user-status " + (x.inviteToken ? "invited" : "active")} title={x.inviteToken ? "Invited" : "Active"}/>
                    </div>

                </div>
            })}
        </div>
    }
}

const mapStateToProps = (state) => ({
    allUsers: state.CustomerReducer.allUsers
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CustomerActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
