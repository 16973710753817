import { PartnerActionTypes } from "../../types";
import { BytehogsAPI } from "../BytehogsAPI";

const SERVICE = "ac-cb";

export const PartnerActionCreators = {
  search: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.SEARCH_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "search" };
      const response = await BytehogsAPI(SERVICE, "distributors", payload);
      dispatch({
        type: PartnerActionTypes.SEARCH_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.SEARCH_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  searchByName: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.SEARCH_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "searchByName" };
      const response = await BytehogsAPI(SERVICE, "distributors", payload);
      dispatch({
        type: PartnerActionTypes.SEARCH_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.SEARCH_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getInfo: (distributorId) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.MORE_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { distributorId, sessionToken, operation: "getMore" };
      const response = await BytehogsAPI(SERVICE, "distributors", payload);
      dispatch({
        type: PartnerActionTypes.MORE_RES,
        payload: { distributorId, data: response.data },
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.MORE_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getAllDistributorNames: () => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.GET_ALL_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = {sessionToken, operation: "getAllBasic" };
      const response = await BytehogsAPI(SERVICE, "distributors", payload);
      dispatch({
        type: PartnerActionTypes.GET_ALL_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.GET_ALL_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  contactPartner: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.CONTACT_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { ...data, sessionToken, operation: "contact" };
      await BytehogsAPI(SERVICE, "distributors", payload);
      dispatch({
        type: PartnerActionTypes.CONTACT_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.CONTACT_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  getTestimonialByToken: (token) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.GET_TESTI_REQ_REQ,
    });
    try {
      let payload = { operation: "getDataByToken", token };
      const response = await BytehogsAPI(SERVICE, "testimonials", payload);
      dispatch({
        type: PartnerActionTypes.GET_TESTI_REQ_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.GET_TESTI_REQ_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  addTestimonial: (data) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.ADD_TESTI_REQ,
    });
    try {
      let payload = { ...data, operation: "add" };
      await BytehogsAPI(SERVICE, "testimonials", payload);
      dispatch({
        type: PartnerActionTypes.ADD_TESTI_RES,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.ADD_TESTI_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
  leaveTestimonial: (distributorId) => async (dispatch) => {
    dispatch({
      type: PartnerActionTypes.LEAVE_TESTI_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = { distributorId, sessionToken, operation: "leaveTestimonial" };
      const response = await BytehogsAPI(SERVICE, "distributors", payload);
      dispatch({
        type: PartnerActionTypes.LEAVE_TESTI_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log("e", e);
      dispatch({
        type: PartnerActionTypes.LEAVE_TESTI_RES,
        error: e.data ? e.data.message : "Something went wrong",
      });
    }
  },
};
