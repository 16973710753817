import { CustomerActionTypes } from "../../types";

export const initialState = {
  loading: false,
  updateDetailsErr: null,
  getDetailsErr: null,
  customerDetails: {filters:{}},
  updateFiltersErr: null,
  userErr: null,
  user: {},
  inviteUserErr: null,
  allUsers: [],
  clients: []
};

export const CustomerReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case CustomerActionTypes.UPDATE_DETAILS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case CustomerActionTypes.UPDATE_DETAILS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          updateDetailsErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        updateDetailsErr: null,
      };
    }
    case CustomerActionTypes.GET_DETAILS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case CustomerActionTypes.GET_DETAILS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          getDetailsErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        getDetailsErr: null,
        customerDetails: payload.client,
      };
    }
    case CustomerActionTypes.UPDATE_USER_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case CustomerActionTypes.VERIFY_EMAIL_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case CustomerActionTypes.VERIFY_EMAIL_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          verifyEmailErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        verifyEmailErr: null,
      };
    }
    case CustomerActionTypes.UPDATE_USER_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          userErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        userErr: null,
      };
    }
    case CustomerActionTypes.GET_USER_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case CustomerActionTypes.GET_USER_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          userErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        userErr: null,
        user: payload.user,
      };
    }
    case CustomerActionTypes.UPDATE_FILTERS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case CustomerActionTypes.UPDATE_FILTERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          updateFiltersErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        updateFiltersErr: null,
      };
    }
    case CustomerActionTypes.INVITE_USERS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case CustomerActionTypes.INVITE_USERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          inviteUserErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        inviteUserErr: null,
      };
    }
    case CustomerActionTypes.GET_USERS_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case CustomerActionTypes.GET_USERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          userErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        userErr: null,
        allUsers: payload.team,
      };
    }
    case CustomerActionTypes.GET_CUSTOMERS_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case CustomerActionTypes.GET_CUSTOMERS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          userErr: error
        }
      }
      return {
        ...state,
        loading: false,
        userErr: null,
        clients: payload.clients
      }
    }
    case "LOGOUT": {
      return initialState;
    }
    default:
      return state;
  }
};
