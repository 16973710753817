import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {CustomerActionCreators} from "../../redux/actions/customer";
import {connect} from "react-redux";

const newUsers = [0,1,2,3,4].map(i => ({email:"", role: "member"}));

class InviteTeamForm extends Component {
    state = {users: newUsers};

    handleSubmit = async () => {
        const {actions, onSuccess} = this.props;
        const {users} = this.state;
        try {
            let cleansedUsers = [];
            users.forEach(x => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(x.email)) && cleansedUsers.push(x));
            if(cleansedUsers.length > 0) {
                await actions.inviteUsers(users);
                const {inviteUserErr} = this.props;
                if(inviteUserErr) {
                    console.log("e", inviteUserErr);
                    //TODO: error handling
                } else {
                    onSuccess();
                    this.setState({users: newUsers});
                }
            } else {

            }
        } catch (e) {
            console.log("e", e);
            //TODO: error handling
        }
    }

    handleTextChange = (e,i) => {
        const {users} = this.state;
        let allUsers = [...users];
        allUsers[i].email = e.target.value;
        this.setState({users: allUsers});
    }

    handleRoleChange = (role,i) => {
        const {users} = this.state;
        let allUsers = [...users];
        allUsers[i].role = role;
        this.setState({users: allUsers});
    }

    render() {
        const {users} = this.state;
        return <div className="invite-team-form">
            <div className="row d-none d-md-flex">
                <div className="col-6">
                    <label>Email</label>
                </div>
                <div className="col-3">
                    <label>Role</label>
                </div>
            </div>

            {users.map((x,i) => {
                return <div className="row input-row" key={i}>
                    <div className="col-md-6">
                        <input type="text"
                               className="form-control"
                               placeholder="Email address"
                               onChange={e => this.handleTextChange(e,i)}
                                value={x.email}/>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className= "col-6">
                                <button className={"btn btn-block " + (x.role === "admin" ? "btn-primary" : "btn-outline-primary")} onClick={() => this.handleRoleChange("admin", i)}>Admin</button>
                            </div>
                            <div className= "col-6">
                                <button className={"btn btn-block " + (x.role === "member" ? "btn-primary" : "btn-outline-primary")} onClick={() => this.handleRoleChange("member", i)}>Member</button>
                            </div>
                        </div>
                    </div>
                </div>
            })}
            <button className="btn btn-primary btn-block" onClick={this.handleSubmit}>Send Invitations</button>
        </div>
    }
}

const mapStateToProps = (state) => ({
    inviteUserErr: state.CustomerReducer.inviteUserErr
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CustomerActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteTeamForm);
