import React from "react";
import {RatingContainer} from "../global/RatingContainer";

export const FullTestimonialDetails = props =>  {
    const {satisfaction,
        prodSatisfaction,
        trainingQuality,
        salesRepRel,
        partnershipFlex,
        afterSalesService,
        marketing,
        reliability,
        /* product, */
        recommendDist,
       /*  recommendProd,
        optimiseProfit,
        optimiseAmount, createdAt, */
        prodKnowledge,
        comments} = props;
    //let submitted = new Date(createdAt)
    //if(!projectName) return null;
    return <div className="full-testimonial-details">
       {/*<h5>Anonymous</h5>*/}
        {/*<div className="top">
            <div className="company-name">Anonymous</div>
            <div className="dates">
                {`${getShortMonthName(submitted.getMonth())} ${submitted.getFullYear()}`}
            </div>
        </div>*/}
        <div className="details">
            <div className="row">
                <div className="col-md-7">
                    <span className="title">Satisfaction</span>
                </div>
                <div className="col-md-5">
                    <RatingContainer rating={Number(satisfaction)} hideRating/>
                </div>
                <div className="col-md-7">
                    <span className="title">Product Satisfaction</span>
                </div>
                <div className="col-md-5">
                    <RatingContainer rating={Number(prodSatisfaction)} hideRating/>
                </div>
                <div className="col-md-7">
                    <span className="title">Training Quality</span>
                </div>
                <div className="col-md-5">
                    <RatingContainer rating={Number(trainingQuality)} hideRating/>
                </div>
                <div className="col-md-8">
                    <span className="title">Sales Representative Relationship</span>
                </div>
                <div className="col-md-4">
                    <RatingContainer rating={Number(salesRepRel)} hideRating/>
                </div>
                <div className="col-md-8">
                    <span className="title">Product knowledge</span>
                </div>
                <div className="col-md-4">
                    <RatingContainer rating={Number(prodKnowledge)} hideRating/>
                </div>
                <div className="col-md-8">
                    <span className="title">Partnership Flexibility</span>
                </div>
                <div className="col-md-4">
                    <RatingContainer rating={Number(partnershipFlex)} hideRating/>
                </div>
                <div className="col-md-7">
                    <span className="title">After Sales Service</span>
                </div>
                <div className="col-md-5">
                    <RatingContainer rating={Number(afterSalesService)} hideRating/>
                </div>
                <div className="col-md-7">
                    <span className="title">Marketing</span>
                </div>
                <div className="col-md-5">
                    <RatingContainer rating={Number(marketing)} hideRating/>
                </div>
                <div className="col-md-7">
                    <span className="title">Reliability</span>
                </div>
                <div className="col-md-5">
                    <RatingContainer rating={Number(reliability)} hideRating/>
                </div>
                <div className="col-md-7">
                    <span className="title">Likely to recommend distributor</span>
                </div>
                <div className="col-md-5">
                    <RatingContainer rating={Number(recommendDist)} hideRating/>
                </div>
            </div>
            <p>{comments}</p>
        </div>
    </div>
};
