import React from "react";
import Modal from "react-modal";
import {Link} from "react-router-dom";

export const TestimonialSuccessModal = props => {
        const {isOpen} = props;

        return <Modal
            isOpen={isOpen}
            onRequestClose={() => {}}
            className={"modal animated testimonial-success"}
            overlayClassName="modal-overlay"
            contentLabel="Testimonial details Modal">
                <div>
                        <h1>Review Posted</h1>
                        <p>Your review has successfully been submitted!</p>
                        <Link className="btn btn-primary" to="/login">Sign In</Link>
                        <a href="https://www.aesthetixconnect.com/distributors" className="btn btn-outline-primary">Back to Search</a>
                </div>
        </Modal>
}
