import React, { useState, useRef } from "react";

export const SearchBox = (props) => {
  const { search } = props;
  const inputEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  const handleToggle = () => {
    if (!open) inputEl.current.focus();
    setOpen(!open);
  };

  const handleSearch = () => {
    search(query);
    handleToggle();
    setQuery("");
  };

  const handleKeyUp = (e) => {
    if ((e.key === "Enter" || e.keyCode === 13) && query.length > 1) {
      handleSearch();
    }
  };

  return (
    <div className={"search-box " + (open ? "open" : "closed")}>
      <div className="main">
        <div className="input-container">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            ref={inputEl}
            onKeyUp={handleKeyUp}
          />
        </div>
        <div className="search-toggle" onClick={handleToggle}>
          <img
            src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-search.svg"
            alt="search"
          />
        </div>
      </div>
      <div
        className={
          "search-handler " + (query.length > 1 && open ? "open" : "closed")
        }
      >
        <div className="inner">
          <span>Find all results matching "{query}..."</span>
          <button className="btn btn-primary" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};
