import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SEO } from "../../components/global/SEO";
import { withToastManager } from "react-toast-notifications";
import { GeneralActionCreators } from "../../redux/actions/general";
import { BytehogsPillContainer } from "../../components/profile/BytehogsPillContainer";
import { CustomerActionCreators } from "../../redux/actions/customer";
import { MultiFileUploader } from "../../components/rfps/MultiFileUploader";
import { RFPActionCreators } from "../../redux/actions/rfp";
import { Back } from "../../components/global/Back";
import CURRENCIES from "../../currencies.json";
import Select from "react-select";

class CreateRFP extends Component {
  state = {
    title: "",
    name: "",
    sectors: [],
    services: [],
    products: [],
    regions: [],
    description: "",
    currency: "£",
    expectedBudget: "",
    implBudget: "",
    support: 0,
    anonymous: 0,
    existing: 0,
    listing: 0,
    attachments: [],
    errors: {},
    loading: false,
    valid: 0,
    success: false,
  };

  async componentWillMount() {
    const { genActions, cusActions, baseData, toastManager } = this.props;
    await cusActions.getDetails();
    if (JSON.stringify(baseData) === "{}") {
      try {
        await genActions.getBaseData();
      } catch (e) {
        toastManager.add("Something went wrong.", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
    /* const { customerDetails } = this.props; */
    //this.setState({business_areas: customerDetails.filters.business_areas})
  }

  handlePillClick = (key, id, multi = true) => {
    let data = this.state[key];
    if (data.includes(id)) data.splice(data.indexOf(id), 1);
    else multi ? data.push(id) : (data = [id]);
    this.setState({ [key]: data });
  };

  setValue = (key, value) => this.setState({ [key]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      sectors,
      services,
      regions,
      expectedBudget,
      description,
      currency,
      anonymous,
      listing,
      attachments,
      loading,
        title,
    } = this.state;

    if (!loading) {
      let errors = {};
      this.setState({ errors, loading: true });
      if (!title) errors.title = "Required";
      if (!description) errors.description = "Required";
      else if (description.length < 50)
        errors.description = "Minimum 50 characters";
      if (!sectors || sectors.length === 0) errors.sectors = "Required";
      if (!services || services.length === 0) errors.services = "Required";
      //if (!expectedBudget) errors.expectedBudget = "Required";

      if (Object.keys(errors).length > 0)
        this.setState({ errors, loading: false });
      else {
        const { actions } = this.props;
        try {
          let filters = [];
          sectors.forEach((x) =>
            filters.push({ filterId: x, filterType: "sectors" })
          );
          services.forEach((x) =>
            filters.push({ filterId: x, filterType: "services" })
          );
          regions.forEach((x) =>
            filters.push({ filterId: x, filterType: "regions" })
          );
          const theAttachments = attachments.map((x) => ({
            name: x.name,
            type: x.type,
          }));
          await actions.create(
            {
              title,
              description,
              filters,
              expectedBudget: currency + expectedBudget,
              anonymous,
              attachments: theAttachments,
              listing
            },
            attachments
          );
          const { createRFPErr, toastManager, history } = this.props;
          if (createRFPErr)
            this.setState({ errors: { join: createRFPErr }, loading: false });
          else {
            toastManager.add("Successfully created request.", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: false,
            });
            history.push("/requests");
          }
        } catch (e) {
          this.setState({
            errors: { join: e.message || "Sorry, something went wrong" },
            loading: false,
          });
        }
      }
    }
  };


  loadActiveRegions = () => {
    const {regions} = this.state;
    return regions ? regions.map((x) => ({ value: x })) : [];
  };

  loadRegions = () => {
    const {baseData} = this.props;
    if (!baseData || !baseData.regions) return [];
    return baseData.regions.map((x) => ({ value: x.id, label: x.name }));
  };

  handleRegionSelect = (regions) => {
    this.setState({ regions });
  }

  removeRegion = (value) => {
    const regions = this.state.regions.filter((x) => x !== value && x);
    this.setState({ regions });
  };

  render() {
    const {
      title,
      sectors,
      services,
      expectedBudget,
      description,
      currency,
      attachments,
      errors,
      loading,
    } = this.state;
    const { baseData, history } = this.props;
    return (
      <div className="view set-testimonial create-rfp">
        <SEO
          title="Create RFP on Aesthetix Connect | Customers"
          slug="/requests/create"
          creator="aesthetixconnect"
        />
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <Back onClick={() => history.push("/requests")} />
            <h1>Create New Request</h1>
            <div className="form-container">
              <div className="form-content">
                <label>Request Title</label>
                <div className="wl-input-group">
                  {errors.title && (
                    <div className="input-err-message">
                      {errors.title}
                    </div>
                  )}
                  <input
                    className={
                      "form-control" + (errors.title ? " error" : "")
                    }
                    value={title}
                    onChange={(e) =>
                      this.setValue("title", e.target.value)
                    }
                  />
                </div>
                <label>Request Description</label>
                <div className="wl-input-group">
                  {errors.description && (
                    <div className="input-err-message">
                      {errors.description}
                    </div>
                  )}
                  <textarea
                    className={
                      "form-control" + (errors.description ? " error" : "")
                    }
                    value={description}
                    onChange={(e) =>
                      this.setValue("description", e.target.value)
                    }
                    rows={8}
                  />
                </div>
                <div className="pill-group">
                  <label>Which regions are you looking for support in?</label>
                  <BytehogsPillContainer
                      active={this.loadActiveRegions().map((x) => x.value)}
                      options={baseData.regions || []}
                      onClick={this.removeRegion}
                      selectedOnly
                  />
                  <Select
                      value={this.loadActiveRegions()}
                      isMulti
                      hideSelectedOptions
                      controlShouldRenderValue={false}
                      backspaceRemovesValue={false}
                      className="BHG-search-select"
                      classNamePrefix="BHG-search-select"
                      onChange={(x) =>
                          this.handleRegionSelect(x.map((x) => x.value) || [])
                      }
                      options={this.loadRegions()}
                  />
                </div>
                <div className="pill-group">
                  <label>Which sectors are you looking for support in?</label>
                  <BytehogsPillContainer
                    active={sectors}
                    options={baseData.sectors || []}
                    onClick={(x) => this.handlePillClick("sectors", x)}
                  />
                  {errors.sectors && (
                    <div className="form-error">{errors.sectors}</div>
                  )}
                </div>
                <div className="pill-group">
                  <label>
                    Which services are you looking for support in?
                  </label>
                  <BytehogsPillContainer
                    active={services}
                    options={baseData.services || []}
                    onClick={(x) => this.handlePillClick("services", x)}
                  />
                  {errors.services && (
                    <div className="form-error">{errors.services}</div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <label>Avg Expected Budget</label>
                    <div className="wl-input-group">
                      {errors.currency && (
                        <div className="input-err-message">
                          {errors.currency}
                        </div>
                      )}
                      <select
                        className="form-control"
                        value={currency}
                        onChange={(e) =>
                          this.setValue("currency", e.target.value)
                        }
                      >
                        {CURRENCIES.map(x => <option value={x.icon} key={x.icon}>{x.label}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="wl-input-group override">
                      {errors.expectedBudget && (
                        <div className="input-err-message">
                          {errors.expectedBudget}
                        </div>
                      )}
                      <input
                        type="number"
                        className={
                          "form-control" +
                          (errors.expectedBudget ? " error" : "")
                        }
                        placeholder="e.g 5,000"
                        value={expectedBudget}
                        onChange={(e) =>
                          this.setValue("expectedBudget", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <label>Attachments (Optional)</label>
                <MultiFileUploader
                  handleChange={(attch) =>
                    this.setState((prevState) => ({
                      attachments: [...prevState.attachments, ...attch],
                    }))
                  }
                  files={attachments}
                />
                {errors.join && <div className="form-error">{errors.join}</div>}
                <div className="buttons">
                  <button
                    type="submit"
                    className="btn btn-primary submit-btn"
                    onClick={() => this.setValue("anonymous", 0)}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Post Request"}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-primary submit-btn"
                    onClick={() => this.setValue("anonymous", 1)}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Post Anonymously"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  createRFPErr: state.RFPReducer.createRFPErr,
  customerDetails: state.CustomerReducer.customerDetails,
  baseData: state.GeneralReducer.baseData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(RFPActionCreators, dispatch),
  genActions: bindActionCreators(GeneralActionCreators, dispatch),
  cusActions: bindActionCreators(CustomerActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(CreateRFP)
);
