import React from "react";

export const MultiFileUploader = (props) => {
  const { handleChange, files } = props;
  const fileArray = [];
  for (let i = 0; i < files.length; i++) {
    //let fileList = files[i];
    //for(let x = 0; x < fileList.length; x++) {
    let file = files[i];
    fileArray.push(file.name);
    //}
  }
  return (
    <div className="multi-file-uploader">
      <input
        type="file"
        id="multi-file-uploader"
        onChange={(e) => handleChange(e.target.files)}
        multiple
      />
      {fileArray.map((name) => (
        <div className="file" key={name}>
          <img
            src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-document.svg"
            alt="Document"
            className="file-icon"
          />
          <span>
            {name.length > 10
              ? name.substring(0, 7) + "..." + name.substr(name.length - 4)
              : name}
          </span>
        </div>
      ))}
      <label htmlFor="multi-file-uploader" className="add-new">
        <img
          src="https://s3.eu-west-2.amazonaws.com/static.partnerlynx.com/icons/pl-attachment.svg"
          alt="Attachment"
          className="attachment-icon"
        />
        <span>Attach Files</span>
      </label>
    </div>
  );
};
